<template>
  <div class="loading_container">
    <h2 class="text-success text-center">
      Login efetuado com sucesso.<br />
      Você pode fechar esta janela e retomar o seu trabalho.
    </h2>
  </div>
</template>

<script>
export default {
  created() { },

  components: {},

  data() {
    return {};
  },

  methods: {},
};
</script>
