import LoginPage from "../../vue/components/pages/LoginPage.vue";
import DashboardPage from "../../vue/components/pages/DashboardPage.vue";
import AssistantListPage from "../../vue/components/pages/AssistantListPage.vue";
import ClientListPage from "../../vue/components/pages/ClientListPage.vue";
import ProjectListPage from "../../vue/components/pages/ProjectListPage.vue";
import ProposalListPage from "../../vue/components/pages/ProposalListPage.vue";
import VideoListPage from "../../vue/components/pages/VideoListPage.vue";
import CourseListPage from "../../vue/components/pages/CourseListPage.vue";
import ClassPage from "../../vue/components/pages/ClassPage.vue"; 
import AssistantPage from "../../vue/components/pages/AssistantPage.vue";
import ClientPage from "../../vue/components/pages/ClientPage.vue";
import ProjectPage from "../../vue/components/pages/ProjectPage.vue";
import ProposalPage from "../../vue/components/pages/ProposalPage.vue";
import VideoPage from "../../vue/components/pages/VideoPage.vue";

import NotFoundPage from "../../vue/components/pages/NotFoundPage.vue";
import LoginSuccessPage from "../../vue/components/pages/LoginSuccessPage.vue";
import LoginErrorPage from "../../vue/components/pages/LoginErrorPage.vue";

const routes = [
    { path: ``, component: DashboardPage },
    { path: `/login`, component: LoginPage },
    { path: `/erro-login`, component: LoginErrorPage },
    { path: `/login-feito`, component: LoginSuccessPage },
    { path: `/assistentes`, component: AssistantListPage },
    { path: `/clientes`, component: ClientListPage },
    { path: `/projetos`, component: ProjectListPage },
    { path: `/propostas`, component: ProposalListPage },
    { path: `/videos`, component: VideoListPage },
    { path: `/cursos`, component: CourseListPage },
    { path: `/curso/:courseId/:classId`, component: ClassPage },
    { path: `/assistente/:assistantId`, component: AssistantPage },
    { path: `/cliente/:clientId`, component: ClientPage },
    { path: `/projeto/:projectId`, component: ProjectPage },
    { path: `/proposta/:assistantId/:projectId`, component: ProposalPage },
    { path: `/video/:videoId`, component: VideoPage },
    { path: "/:catchAll(.*)", component: NotFoundPage }
];

export default routes;