<template>
  <div class="side_navbar">
    <div class="user_profile">
      <a href="/">
        <img :src="userPicture" v-if="userPicture" alt="" />
        <img class="empty-image" v-else alt="" />
      </a>
      <p class="client_name">{{ userName }}</p>
      <div class="text-center mt-2">
        <a class="link" :href="logoutURL">Log out</a>
      </div>
    </div>
    <div class="nav">
      <ul>
        <li>
          <a class="nav-link" :class="{ active: this.section === 'DashboardPage' }" href="/"><i
              class="fa-solid fa-house icons"></i>Dashboard</a>
        </li>
        <li>
          <a class="nav-link" href="/assistentes"
            :class="{ active: this.section === 'AssistantListPage' || this.section === 'AssistantPage' }"><i
              class="fa-solid fa-user-graduate icons"></i>Assistentes</a>
        </li>
        <li>
          <a class="nav-link" href="/clientes"
            :class="{ active: this.section === 'ClientListPage' || this.section === 'ClientPage' }"><i
              class="fa-solid fa-user icons"></i>Clientes</a>
        </li>
        <li>
          <a class="nav-link" href="/projetos"
            :class="{ active: this.section === 'ProjectListPage' || this.section === 'ProjectPage' }"><i
              class="fa-solid fa-briefcase icons"></i>Projetos</a>
        </li>
        <li>
          <a class="nav-link" href="/propostas"
            :class="{ active: this.section === 'ProposalListPage' || this.section === 'ProposalPage' }"><i
              class="fa-regular fa-file-lines icons"></i> Propostas</a>
        </li>

        <li>
          <a class="nav-link" href="/videos"
            :class="{ active: this.section === 'VideoListPage' || this.section === 'VideoPage' }"><i
              class="fa-brands fa-youtube icons"></i>Vídeos</a>
        </li>

        <li>
          <a class="nav-link" href="/cursos"
            :class="{ active: this.section === 'CourseListPage' || this.section === 'ClassPage' }"><i
              class="fa-solid fa-graduation-cap icons"></i>Cursos</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Config from "../../../resources/config.js";

export default {
  data() {
    return {};
  },
  computed: {
    section() {
      return this.$store.state.activeSection;
    },
    userName() {
      let user = this.$store.state.activeUser;
      if (user) {
        return user.name;
      }
      return null;
    },
    userPicture() {
      let user = this.$store.state.activeUser;
      if (user && user.picture) {
        return this.getImageURL(user.picture);
      }
      return null;
    },
    logoutURL() {
      return `${Config.apiHost}/logout`;
    },
  },

  methods: {},
};
</script>
