<template>
    <div class="talent_profile proposallls">
        <div class="talent-info">
            <div class="freelancer_info">
                <div class="freelancer">
                    <a target="_blank" :href="'/assistente/' + proposal.assistant.id">
                        <img class="freelancer_img" :src="getImageURL(proposal.assistant.picture)"
                            v-if="proposal.assistant.picture" alt="" />
                        <img class="freelancer_img empty-image" v-else alt="" />
                    </a>
                    <div class="info">
                        <div>
                            <a target="_blank" :href="'/assistente/' + proposal.assistant.id">
                                <h2>
                                    {{ proposal.assistant.name }}
                                </h2>
                            </a>
                            <p>{{ proposal.assistant.title }}</p>
                        </div>
                        <div class="down-icons-list">
                            <div class="ex_icon" v-if="proposal.assistant.cert_av || proposal.assistant.cert_ap">
                                <span v-if="proposal.assistant.cert_av" class="cert_av"><i class="fa-solid fa-laptop icons"></i></span>
                                <span v-if="proposal.assistant.cert_ap" class="cert_ap"><i class="fa-solid fa-star icons"></i></span>
                                <!-- <span
                                      ><i class="fa-solid fa-trophy icons"></i
                                    ></span> -->
                            </div>

                            <div class="star">                                
                                <i :class="star.class" class="icons" v-for="star in getRatingStars(proposal.assistant.feedback_rating)" :key="star.id"></i>
                                <span class="rating"> ({{ proposal.assistant.feedback_rating.toFixed(2) }})</span>
                            </div>

                        </div>
                        <p class="country">{{ proposal.assistant.location }}</p>
                    </div>
                </div>
                <a class="btn" style="cursor: pointer" href="javascript:void(0)" @click="editProposal">Editar proposta</a>
            </div>
        </div>


        <div class="keywords-group">
            <div class="ky_btn" v-if="proposal.assistant.area_ap">
                Assistência Pessoal
            </div>
            <div class="ky_btn" v-if="proposal.assistant.area_ae">
                Secretariado Executivo
            </div>
            <div class="ky_btn" v-if="proposal.assistant.area_sa">
                Suporte Administrativo
            </div>
            <div class="ky_btn" v-if="proposal.assistant.area_sr">
                Secretariado remoto
            </div>
            <div class="ky_btn" v-if="proposal.assistant.area_si">
                Suporte para Infoprodutores
            </div>
            <div class="ky_btn" v-if="proposal.assistant.area_ed">
                Entrada de dados
            </div>
        </div>
        <!-- <hr class="mt-5" /> -->
        <div class="description_part mt-4">
            <p class="break-word break-line">{{ proposal.proposal }}</p>
        </div>
    </div>
</template>
  
<script>
import AdminManager from "../../../js/services/admin.js";
export default {
    props: ["project", "proposal"],
    data() {
        return {
        };
    },
    computed: {
    },
    methods: {
        editProposal() {
            let $ctx = this;
            let $modal = $("#editProposalModal");
            $modal.find(".item").removeClass("error");

            let action = async function () {
                let proposalDetails = {
                    proposal: $modal.find("[data-proposal-description]").val(),
                };

                let hasError = false;
                $modal.find(".item").removeClass("error");

                if (proposalDetails.proposal.length < 50) {
                    $modal
                        .find("[data-proposal-description]")
                        .closest(".item")
                        .addClass("error");
                    hasError = true;
                }

                if (hasError) {
                    return false;
                }

                let updatedProposal = await AdminManager.updateProposal(
                    $ctx.proposal.assistant.id,
                    $ctx.proposal.project.id,
                    proposalDetails
                );

                $ctx.proposal.proposal = updatedProposal.proposal;
                $ctx.proposal.status = updatedProposal.status;
                return true;
            };

            $modal.find("[data-proposal-description]").val($ctx.proposal.proposal);

            this.showModal(
                $modal,
                function () {
                    $modal.find("[data-proposal-description]").focus();
                },
                "Salvar",
                action,
                "Proposta atualizado.",
                "Ocorreu um erro editar a proposta.\nPor favor, tente novamente."
            );
        },
    },
};
</script>
  