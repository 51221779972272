import TimeUtils from "../utils/time.js";
import Config from "../../../resources/config.js";

const $ = window["$"];

function updateWrapBoxes() {
    $(".wrap-box").each(function (index, item) {
        updateWrapBox(item);
    });
}

function updateWrapBox(item) {
    let $box = $(item);
    let p = $box.children("p").eq(0)[0];
    let $label = $box.children("label");

    if (p.scrollHeight > p.clientHeight) {
        $label.show();
    } else {
        if ($box.is('.unclamp')) {
            $label.hide();
            $box.removeClass("unclamp");
        } else {
            $label.hide();
        }
    }
}


$(document).on('click', '.wrap-box label', function () {
    let $wrapBox = $(this).closest('.wrap-box');
    $wrapBox.toggleClass("unclamp");
});

$(window).on('resize', updateWrapBoxes);

export default {
    methods: {

        getImageURL(file) {
            return `${Config.assetsHost}/${file}`;
        },



        getVideoURL(video) {
            return `${Config.videosHost}/${video.path}.m3u8`;
        },

        getVideoThumbnailURL(video) {
            if (video.type === 1) {
                return `${Config.videosHost}/${video.path}.jpeg`;
            } else if (video.type === 2) {
                let youtube_video_id = video.path.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop();
                return `http://img.youtube.com/vi/${youtube_video_id}/maxresdefault.jpg`;
            }
        },

        getCourseClassURL(course, module, classObj) {
            console.log(course, module, classObj);
            return `${Config.coursesHost}/${course.path}/${module.path}/${classObj.path}.m3u8`;
        },

        formatPlural(count, singular, plural) {
            if (count === 1) {
                return count + " " + singular;
            } else {
                return count + " " + plural;
            }
        },

        formatDuration(loanDurationInSeconds) {
            return TimeUtils.formatDuration(loanDurationInSeconds);
        },

        formatElapsedTime(time) {

            let seconds = Math.floor((new Date() - time) / 1000);
            let intervalType;
            if (seconds === 0) {
                return 'Agora mesmo';
            }

            let interval = Math.floor(seconds / 31536000);
            if (interval >= 1) {
                intervalType = 'anos';
            } else {
                interval = Math.floor(seconds / 2592000);
                if (interval >= 1) {
                    if (interval > 1) {
                        intervalType = 'meses';
                    } else {
                        intervalType = 'mês';
                    }
                } else {
                    interval = Math.floor(seconds / 86400);
                    if (interval >= 1) {
                        if (interval > 1) {
                            intervalType = 'dias';
                        } else {
                            intervalType = 'dia';
                        }
                    } else {
                        interval = Math.floor(seconds / 3600);
                        if (interval >= 1) {
                            if (interval > 1) {
                                intervalType = 'horas';
                            } else {
                                intervalType = 'hora';
                            }
                        } else {
                            interval = Math.floor(seconds / 60);
                            if (interval >= 1) {
                                if (interval > 1) {
                                    intervalType = 'minutos';
                                } else {
                                    intervalType = 'minuto';
                                }
                            } else {
                                interval = seconds;
                                if (interval > 1) {
                                    intervalType = 'segundos';
                                } else {
                                    intervalType = 'segundo';
                                }
                            }
                        }
                    }
                }
            }
            return interval + ' ' + intervalType + ' atrás';
        },

        formatMultilineText(text) {
            return $('<div/>').text(text).html().replace(new RegExp('\r?\n', 'g'), '<br />');
        },

        getRatingStars(rating) {
            let ret = [];
            for (let i = 0; i < 5; i++) {
                ret.push({ id: i, class: 'fa-regular fa-star' });
            }
            if (rating >= 0.5 && rating < 1) {
                ret[0].class = 'fa-regular fa-star-half-stroke';
            } else if (rating >= 1 && rating < 1.5) {
                ret[0].class = 'fa-solid fa-star';
            } else if (rating >= 1.5 && rating < 2) {
                ret[0].class = 'fa-solid fa-star';
                ret[1].class = 'fa-regular fa-star-half-stroke';
            } else if (rating >= 2 && rating < 2.5) {
                ret[0].class = 'fa-solid fa-star';
                ret[1].class = 'fa-solid fa-star';
            } else if (rating >= 2.5 && rating < 3) {
                ret[0].class = 'fa-solid fa-star';
                ret[1].class = 'fa-solid fa-star';
                ret[2].class = 'fa-regular fa-star-half-stroke';
            } else if (rating >= 3 && rating < 3.5) {
                ret[0].class = 'fa-solid fa-star';
                ret[1].class = 'fa-solid fa-star';
                ret[2].class = 'fa-solid fa-star';
            } else if (rating >= 3.5 && rating < 4) {
                ret[0].class = 'fa-solid fa-star';
                ret[1].class = 'fa-solid fa-star';
                ret[2].class = 'fa-solid fa-star';
                ret[3].class = 'fa-regular fa-star-half-stroke';
            } else if (rating >= 4 && rating < 4.5) {
                ret[0].class = 'fa-solid fa-star';
                ret[1].class = 'fa-solid fa-star';
                ret[2].class = 'fa-solid fa-star';
                ret[3].class = 'fa-solid fa-star';
            } else if (rating >= 4.5 && rating < 5) {
                ret[0].class = 'fa-solid fa-star';
                ret[1].class = 'fa-solid fa-star';
                ret[2].class = 'fa-solid fa-star';
                ret[3].class = 'fa-solid fa-star';
                ret[4].class = 'fa-regular fa-star-half-stroke';
            } else if (rating >= 5) {
                ret[0].class = 'fa-solid fa-star';
                ret[1].class = 'fa-solid fa-star';
                ret[2].class = 'fa-solid fa-star';
                ret[3].class = 'fa-solid fa-star';
                ret[4].class = 'fa-solid fa-star';
            }

            return ret;
        },

        formatTimestamp(timestamp) {
            let date = new Date(timestamp);
            return date.toLocaleDateString("pt-BR") + " " + date.toLocaleTimeString("pt-BR")
        },

        formatAnnualizedReturn(loanAmount, loanInterest, loanDurationInSeconds) {
            if (!loanAmount || !loanInterest || !loanDurationInSeconds) {
                return "-";
            }

            loanAmount = Number(loanAmount);
            loanInterest = Number(loanInterest);
            loanDurationInSeconds = Number(loanDurationInSeconds);

            let loanDays = loanDurationInSeconds / (24 * 3600);
            let totalReturn = (loanAmount + loanInterest) / loanAmount;
            let arr = Math.pow(totalReturn, (365 / loanDays)) - 1;

            return parseFloat(arr * 100).toFixed(4) + "%";
        },

        formatTotalAmount(loan) {
            if (!loan || !loan.amount || !loan.interest) {
                return "-";
            }

            return this.formatPrice(BigInt(loan.amount) + BigInt(loan.interest));
        },

        buildSessionLink() {
            return "Sua sessão expirou.\n<a onclick='popupWindow(\"" + Config.apiHost + "/auth/google/admin?popup=true\",\"_blank\",window, 600, 400)' target='_blank' class='link'> Clique aqui para entrar novamente</a>";
        },

        formatAssistantStatus(status) {
            switch (status) {
                case 1:
                    return "Em análise";
                case 2:
                    return "Ativo";
                case 3:
                    return "Inativo";
                default:
                    return "Desconhecido";
            }
        },

        formatClientStatus(status) {
            switch (status) {
                case 1:
                    return "Em análise";
                case 2:
                    return "Ativo";
                case 3:
                    return "Inativo";
                default:
                    return "Desconhecido";
            }
        },

        formatProjectStatus(status) {
            switch (status) {
                case 1:
                    return "Em análise";
                case 2:
                    return "Ativo";
                case 3:
                    return "Inativo";
                case 4:
                    return "Fechado";
                default:
                    return "Desconhecido";
            }
        },

        formatProposalStatus(status) {
            switch (status) {
                case 1:
                    return "Em análise";
                case 2:
                    return "Ativa";
                case 3:
                    return "Desativada";
                default:
                    return "Desconhecido";
            }
        },

        getBtnStatusClass(status) {
            switch (status) {
                case 1:
                    return "bg-warning";
                case 2:
                    return "bg-success";
                case 3:
                    return "bg-danger";
                case 4:
                    return "bg-orange";
                default:
                    return "bg-dark";
            }
        },

        getTextStatusClass(status) {
            switch (status) {
                case 1:
                    return "text-warning";
                case 2:
                    return "text-success";
                case 3:
                    return "text-danger";
                case 4:
                    return "text-orange";
                default:
                    return "text-dark";
            }
        },

        handleAPIError(error, sourcePage) {
            console.log(error);
            if (error.status === 401) {
                window.location = Config.loginPage + (sourcePage ? "?next=" + sourcePage : "");
            } else {
                this.$store.commit("setError", error);
                this.$store.commit("setLoading", false);
            }
        },

        showError(err) {
            console.log(err);
            let error;
            if (err.data && err.data.data && err.data.data.message) {
                error = err.data.data.message;
            } else if (err.message) {
                error = err.message;
            } else {
                error = err;
            }

            this.$store.commit("setLoading", false);

            $("#errorMsg").text(error);
            $("#errorModal").modal("show");

        },

        showSuccess(msg) {
            $("#successMsg").text(msg);
            $("#successModal").modal("show");
        },

        showConfirmation(title, confirmationMessage, confirmButtonTitle, confirmAction, successMessage, errorMessage) {
            let $confirmModal = $("#confirmModal");
            $confirmModal.find("[data-title]").text(title);
            $confirmModal.find("[data-msg]").text(confirmationMessage);

            this.showModal($confirmModal, function () { }, confirmButtonTitle, confirmAction, successMessage, errorMessage);
        },

        showModal(modal, init, confirmButtonTitle, confirmAction, successMessage, errorMessage) {
            let $ctx = this;
            let $modal = $(modal);
            let $modalError = $modal.find('[data-error]')
            let $btnConfirmAction = $modal.find('[data-confirm]');

            $modalError.hide();
            $btnConfirmAction.text(confirmButtonTitle);

            $modal.off().on('shown.bs.modal', function () {
                init($modal);
            });

            $btnConfirmAction.off().on("click", async function () {
                try {
                    $modal
                        .find("input, textarea, button, .btn")
                        .addClass("disabled")
                        .prop("disabled", true);

                    $btnConfirmAction.text("Por favor, aguarde...");
                    $modalError.hide();

                    if (await confirmAction()) {
                        $modal.modal("hide");
                        $ctx.showSuccess(successMessage);
                    }
                } catch (error) {
                    console.log(error);
                    if (error.status === 401) {
                        $modalError.html($ctx.buildSessionLink()).show();
                    } else {
                        console.log(error);
                        $modalError
                            .text(errorMessage
                            )
                            .show();
                    }
                } finally {
                    $modal
                        .find("input, textarea, button, .btn")
                        .removeClass("disabled")
                        .prop("disabled", false);

                    $btnConfirmAction.text(confirmButtonTitle);
                }
            });

            $modal.modal("show");

        },

        showCreateProject(AdminManager, assistantId) {
            let $ctx = this;
            let $createProjectModal = $("#createProjectModal");
            let $createProjectError = $("#createProjectError");
            let $projectTitleInput = $("#txtProjectTitle");
            let $projectDescriptionInput = $("#txtProjectDescription");
            let $chkProjectTerms = $("#chkProjectTerms");
            let $btnCreateProject = $("#btnCreateProject");

            $projectTitleInput.val("");
            $projectDescriptionInput.val("");
            $chkProjectTerms.prop("checked", false);

            $createProjectError.hide();

            function isValidInput() {
                if ($projectTitleInput.val().length < 5) {
                    return false;
                }

                if ($projectDescriptionInput.val().length < 50) {
                    return false;
                }

                if (!$chkProjectTerms.is(':checked')) {
                    return false;
                }

                return true;
            }

            function enableButton() {
                if (isValidInput()) {
                    $btnCreateProject.prop("disabled", false).removeClass("disabled");
                } else {
                    $btnCreateProject.prop("disabled", true).addClass("disabled");
                }
            }

            $projectTitleInput.on('input', enableButton);
            $projectDescriptionInput.on('input', enableButton);
            $chkProjectTerms.on('input', enableButton);

            if (assistantId) {
                $btnCreateProject.data('assistantId', assistantId);
            } else {
                $btnCreateProject.data('assistantId', null);
            }

            $btnCreateProject
                .off()
                .on("click", async function () {
                    let assistantId = $btnCreateProject.data('assistantId');
                    let projectTitle = $projectTitleInput.val();
                    let projectDescription = $projectDescriptionInput.val();
                    try {

                        $createProjectError.hide();

                        $createProjectModal
                            .find("input, textarea, button, .btn")
                            .addClass("disabled")
                            .prop("disabled", true);

                        $btnCreateProject
                            .text("Postando...");

                        let projectDetails = {
                            title: projectTitle,
                            description: projectDescription,
                        };

                        let project = await AdminManager.addProject(projectDetails, assistantId);
                        if ($ctx.$store.state.activeProjectList) {
                            $ctx.$store.state.activeProjectList.unshift(project);
                        }
                        $createProjectModal.modal('hide');
                        $ctx.showSuccess("Seu projeto foi criado!");
                    } catch (error) {
                        console.log(error);
                        if (error.status === 401) {
                            $createProjectError.html($ctx.buildSessionLink()).show();
                        } else {
                            console.log(error);
                            $createProjectError.html("Ocorreu um erro problema criar o projeto. Por favor, tente novamente.").show();
                        }
                    } finally {
                        $createProjectModal
                            .find("input, textarea, button, .btn")
                            .removeClass("disabled")
                            .prop("disabled", false);

                        $btnCreateProject
                            .text("Postar");
                    }
                });

            enableButton();
            $createProjectModal.modal('show');

        },

        showInviteAssistant(AdminManager, assistantId) {
            let $ctx = this;
            let $inviteAssistantModal = $("#inviteAssistantModal");
            let $invitedProject = $("#selectedInvitedProject");
            let $inviteAssistantError = $("#inviteAssistantError");
            let $btnInviteAssistant = $("#btnInviteAssistant");

            $inviteAssistantError.hide();

            function isValidInput() {

                return true;
            }

            function enableButton() {
                if (isValidInput()) {
                    $btnInviteAssistant.prop("disabled", false).removeClass("disabled");
                } else {
                    $btnInviteAssistant.prop("disabled", true).addClass("disabled");
                }
            }

            if (assistantId) {
                $btnInviteAssistant.data('assistantId', assistantId);
            } else {
                $btnInviteAssistant.data('assistantId', null);
            }

            $btnInviteAssistant
                .off()
                .on("click", async function () {
                    let assistantId = $btnInviteAssistant.data('assistantId');
                    let invitedProjectId = $invitedProject.val();
                    try {

                        $inviteAssistantError.hide();

                        $inviteAssistantModal
                            .find("input, textarea, select, button, .btn")
                            .addClass("disabled")
                            .prop("disabled", true);

                        $btnInviteAssistant
                            .text("Por favor, aguarde...");

                        await AdminManager.inviteAssistant(assistantId, invitedProjectId);
                        $inviteAssistantModal.modal('hide');
                        $ctx.showSuccess("Assistente notificada!");
                    } catch (error) {
                        console.log(error);
                        if (error.status === 401) {
                            $inviteAssistantError.html($ctx.buildSessionLink()).show();
                        } else {
                            console.log(error);
                            $inviteAssistantError.html("Ocorreu um erro processar sua requisição. Por favor, tente novamente.").show();
                        }
                    } finally {
                        $inviteAssistantModal
                            .find("input, textarea, select, button, .btn")
                            .removeClass("disabled")
                            .prop("disabled", false);

                        $btnInviteAssistant
                            .text("Contratar");
                    }
                });

            enableButton();
            $inviteAssistantModal.modal('show');

        },

        showContactAssistant(AdminManager, client, project, proposal) {
            let $ctx = this;
            let $contactAssistantModal = $("#contactAssistantModal");
            let $contactAssistantError = $("#contactAssistantError");
            let $btnContactAssistant = $("#btnContactAssistant");

            $contactAssistantError.hide();
            $contactAssistantModal.find('[data-assistant-name]').text(proposal.name);
            $contactAssistantModal.find('[data-client-whatsapp]').text(client.contact_info.whatsapp);

            let count = project.max_contacts - project.released_contacts;

            if (count > 1) {
                $contactAssistantModal.find('[data-contacts-left]').text(this.formatPlural(count - 1, 'contato', 'contatos'));
                $contactAssistantModal.find('[data-has-contacts-left]').show();
                $contactAssistantModal.find('[data-no-contacts-left]').hide();
            } else {
                $contactAssistantModal.find('[data-max-contacts]').text(this.formatPlural(project.max_contacts, 'contato', 'contatos'));
                $contactAssistantModal.find('[data-has-contacts-left]').hide();
                $contactAssistantModal.find('[data-no-contacts-left]').show();
            }

            $btnContactAssistant.data('assistantId', proposal.assistant_id);
            $btnContactAssistant.data('projectId', proposal.project_id);

            $btnContactAssistant
                .off()
                .on("click", async function () {
                    let assistantId = $btnContactAssistant.data('assistantId');
                    let projectId = $btnContactAssistant.data('projectId');
                    try {

                        $contactAssistantError.hide();

                        $contactAssistantModal
                            .find("input, textarea, select, button, .btn")
                            .addClass("disabled")
                            .prop("disabled", true);

                        $btnContactAssistant
                            .text("Por favor, aguarde...");

                        await AdminManager.releaseContact(assistantId, projectId);
                        $contactAssistantModal.modal('hide');
                        project.released_contacts++;
                        proposal.released = true;
                        $ctx.showSuccess("Você será contactado no WhatsApp em breve.");
                    } catch (error) {
                        console.log(error);
                        if (error.status === 401) {
                            $contactAssistantError.html($ctx.buildSessionLink()).show();
                        } else {
                            console.log(error);
                            $contactAssistantError.html("Ocorreu um erro processar sua requisição. Por favor, tente novamente.").show();
                        }
                    } finally {
                        $contactAssistantModal
                            .find("input, textarea, select, button, .btn")
                            .removeClass("disabled")
                            .prop("disabled", false);

                        $btnContactAssistant
                            .text("Continuar");
                    }
                });

            $contactAssistantModal.modal('show');

        },
        applyTruncation() {
            this.$nextTick(function () {
                setTimeout(updateWrapBoxes, 400);
            });;

        }
    }
}