<template>
  <div class="loading_container" v-if="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
  <div class="main" v-else>
    <!-- Side Navbar -->
    <SideBar />
    <!-- Body-Content -->
    <div class="body-content">
      <!-- Top Navbar -->
      <TopBar />
      <!-- Body -->
      <div class="container-fluid body-container">
        <div class="alert alert-warning" v-if="proposal.assistant.status !== 2">
          O status desta proposta não pode ser alterado pois o cadastro da assistente não
          está ativo.
        </div>

        <div
          class="alert alert-warning text-center"
          v-else-if="proposal.status === 1 && proposal.project.status === 2"
        >
          Esta proposta está aguardando moderação.
          <div class="admin-buttons-h mt-3">
            <a class="btn bg-success" @click="approveProposal">Aprovar proposta</a>
            <a class="btn btn-cancel" @click="rejectProposal">Reprovar proposta</a>
          </div>
        </div>
        <div
          class="alert alert-warning text-center"
          v-else-if="proposal.status === 1 && proposal.project.status !== 2"
        >
          Esta proposta está aguardando moderação e só poderá ser reprovada, pois o
          projeto não está ativo.
          <div class="admin-buttons-h mt-3">
            <a class="btn btn-cancel" @click="rejectProposal">Reprovar proposta</a>
          </div>
        </div>

        <div class="alert alert-success text-center" v-else-if="proposal.status === 2">
          Esta proposta está ativa.
          <div class="admin-buttons-h mt-3">
            <a class="btn btn-cancel" @click="inactivateProposal">Desativar proposta</a>
          </div>
        </div>

        <div class="alert alert-danger text-center" v-else-if="proposal.status === 3">
          Esta proposta está desativada.
          <div class="admin-buttons-h mt-3">
            <a class="btn bg-success" @click="activateProposal">Re-ativar proposta</a>
          </div>
        </div>

        <div style="margin-left: 0; margin-right: 0">
          <Proposal :proposal="this.proposal" />
        </div>

        <p class="mt-4 text-muted fw-bold">Projeto</p>
        <div class="row project_area mt-2" style="margin-left: 0; margin-right: 0">
          <div class="col-lg-8">
            <div class="project_info">
              <div class="project_heading break-word">
                <a :href="'/projeto/' + proposal.project.id">
                  <h2 class="mb-0">{{ proposal.project.title }}</h2>
                  <p class="date m-0 p-0 mb-2" style="font-size: 10pt">
                    ID: {{ proposal.project.id }}
                  </p>
                </a>
                <p class="date">
                  Última atualização:
                  {{ formatTimestamp(proposal.project.last_update_timestamp) }}
                </p>
              </div>
              <div class="project_heading">
                <div class="wrap-box">
                  <p class="break-word break-line">
                    <span>{{ proposal.project.description }}</span>
                  </p>
                  <label></label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="status box">
              <h2>
                Status:
                <span class="btn" :class="getBtnStatusClass(proposal.project.status)">{{
                  formatProjectStatus(proposal.project.status)
                }}</span>
              </h2>
            </div>
            <div class="sm_sclient_img box">
              <a :href="'/cliente/' + proposal.project.client_id">
                <img
                  :src="getImageURL(proposal.client.picture)"
                  v-if="proposal.client.picture"
                />
                <img class="empty-image" v-else alt="" />
                <p>{{ proposal.client.name }}</p>
              </a>
            </div>
          </div>
        </div>

        <p class="mt-4 text-muted fw-bold">Feedbacks</p>
        <div
          class="row project_area mt-2 text-center"
          style="margin-left: 0; margin-right: 0; line-height: 1rem"
        >
          <div v-if="proposal.project.status !== 4 && !proposal.review">
            Não é possível adicionar feedback pois o projeto não está fechado.
          </div>
          <div v-else-if="proposal.status !== 2 && !proposal.review">
            Não é possível adicionar feedback pois a proposta não encontra-se ativa.
          </div>
          <div v-else-if="!proposal.released && !proposal.review">
            Não é possível adicionar feedback pois o cliente não aceitou essa proposta.
          </div>
          <div class="col-sm-12" v-else-if="!proposal.review">
            <p>Feedback não cadastrado.</p>
            <button class="btn mt-2" @click="addReview()">Adicionar feedback</button>
          </div>
          <div
            v-else
            style="font-size: 14.2px; color: var(--description-color); text-align: left"
          >

          <div class="alert mt-2 text-center" :class="{'alert-success': proposal.review.status === 2, 'alert-danger': proposal.review.status === 3}">
              <p v-if="proposal.review.status === 2">Este feedback está ativo.</p>
              <p v-if="proposal.review.status === 3">Este feedback está inativo.</p>
              <div class="admin-buttons-h mt-3">
                <a class="btn" @click="editReview()">Editar feedback</a>
                <a class="btn bg-success" @click="activateReview()" v-if="proposal.review.status !== 2">Ativar feedback</a>
                <a class="btn btn-cancel" @click="inactivateReview()" v-if="proposal.review.status !== 3">Desativar feedback</a>
              </div>
            </div>


            <strong>Nota para a assistente: </strong>
            <div class="mb-2">{{ proposal.review.assistant_rating }}</div>
            <strong>Comentário para a assistente: </strong>
            <div style="line-height: 1.25rem"  class="break-all break-line">
              {{ proposal.review.assistant_comment }}
            </div>
            <hr />

            <strong>Nota para o cliente: </strong>
            <div class="mb-2">5</div>
            <strong>Comentário para o cliente: </strong>
            <div style="line-height: 1.25rem" v-if="proposal.review.client_comment" class="break-all break-line">
              {{ proposal.review.client_comment }}
            </div>
            <div v-else style="color: #ccc; font-style: italic" class="break-work break-line">
              Nenhum comentário deixado
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import SideBar from "../SideBar.vue";
import TopBar from "../TopBar.vue";
import Proposal from "../project/Proposal.vue";
import AdminManager from "../../../js/services/admin.js";
import StringUtils from "../../../js/utils/string.js";
import Config from "../../../../resources/config.js";

export default {
  components: { SideBar, TopBar, Proposal },

  created() {
    this.$store.commit("setActiveSection", "ProposalPage");
    this.loadProposal();
  },

  data() {
    return {
      proposal: null,
      errorCode: null,
    };
  },

  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },

  methods: {
    async loadProposal() {
      this.$store.commit("setLoading", true);
      try {
        let response = await AdminManager.getProposal(
          this.$route.params.assistantId,
          this.$route.params.projectId
        );
        this.profile = response.profile;
        this.proposal = response.proposal;
        this.$store.commit("setActiveUser", this.profile);
        this.$store.commit("setLoading", false);
        this.applyTruncation();
      } catch (error) {
        this.handleAPIError(
          error,
          "proposta/" +
            this.$route.params.assistantId +
            "/" +
            this.$route.params.projectId
        );
      }
    },

    approveProposal() {
      let $ctx = this;

      let action = async function () {
        let proposalDetails = {
          status: 2,
        };

        let updatedProposal = await AdminManager.updateProposal(
          $ctx.proposal.assistant.id,
          $ctx.proposal.project.id,
          proposalDetails
        );

        $ctx.proposal = updatedProposal;
        return true;
      };

      this.showConfirmation(
        "Aprovar proposta",
        "Tem certeza que deseja aprovar esta proposta?",
        "Aprovar",
        action,
        "A proposta foi aprovada.",
        "Ocorreu um erro ao aprovar a proposta.\nPor favor, tente novamente."
      );
    },

    activateProposal() {
      let $ctx = this;

      let action = async function () {
        let proposalDetails = {
          status: 2,
        };

        let updatedProposal = await AdminManager.updateProposal(
          $ctx.proposal.assistant.id,
          $ctx.proposal.project.id,
          proposalDetails
        );

        $ctx.proposal = updatedProposal;

        return true;
      };

      this.showConfirmation(
        "Ativar proposta",
        "Tem certeza que deseja ativar esta proposta?",
        "Ativar",
        action,
        "A proposta foi ativada.",
        "Ocorreu um erro ativar a proposta.\nPor favor, tente novamente."
      );
    },

    rejectProposal() {
      let $ctx = this;

      let action = async function () {
        let proposalDetails = {
          status: 3,
        };

        let updatedProposal = await AdminManager.updateProposal(
          $ctx.proposal.assistant.id,
          $ctx.proposal.project.id,
          proposalDetails
        );

        $ctx.proposal = updatedProposal;
        return true;
      };

      this.showConfirmation(
        "Reprovar proposta",
        "Tem certeza que deseja reprovar esta proposta?",
        "Reprovar",
        action,
        "A proposta foi reprovada.",
        "Ocorreu um erro reprovar a proposta.\nPor favor, tente novamente."
      );
    },

    inactivateProposal() {
      let $ctx = this;

      let action = async function () {
        let proposalDetails = {
          status: 3,
        };

        let updatedProposal = await AdminManager.updateProposal(
          $ctx.proposal.assistant.id,
          $ctx.proposal.project.id,
          proposalDetails
        );

        $ctx.proposal = updatedProposal;
        return true;
      };

      this.showConfirmation(
        "Desativar proposta",
        "Tem certeza que deseja desativar esta proposta?",
        "Desativar",
        action,
        "A proposta foi desativada.",
        "Ocorreu um erro desativar a proposta.\nPor favor, tente novamente."
      );
    },

    addReview() {
      let $ctx = this;
      let $modal = $("#addReviewModal");
      $modal.find(".item").removeClass("error");

      $modal.find("[data-assistant-rating]").val(1);
      $modal.find("[data-assistant-comment]").val("");
      $modal.find("[data-client-rating]").val(1);
      $modal.find("[data-client-comment]").val("");

      let action = async function () {
        let reviewDetails = {
          assistant_rating: $modal.find("[data-assistant-rating]").val(),
          assistant_comment: $modal.find("[data-assistant-comment]").val(),
          client_rating: $modal.find("[data-client-rating]").val(),
          client_comment: $modal.find("[data-client-comment]").val(),
        };

        let hasError = false;
        $modal.find(".item").removeClass("error");

        if (reviewDetails.assistant_comment.length < 10) {
          $modal.find("[data-assistant-comment]").closest(".item").addClass("error");
          hasError = true;
        }

        if (reviewDetails.client_comment.length < 10) {
          $modal.find("[data-client-comment]").closest(".item").addClass("error");
          hasError = true;
        }

        if (hasError) {
          return false;
        }

        let updatedReview = await AdminManager.addReview(
          $ctx.proposal.assistant.id,
          $ctx.proposal.project.id,
          reviewDetails
        );

        $ctx.proposal.review = updatedReview.review;
        $ctx.proposal.assistant = updatedReview.assistant;
        return true;
      };

      this.showModal(
        $modal,
        function () {
          $modal.find("[data-assistant-rating]").focus();
        },
        "Salvar",
        action,
        "Feedback adicionado.",
        "Ocorreu um erro ao adicionar o feedback.\nPor favor, tente novamente."
      );
    },

    editReview() {
      let $ctx = this;
      let $modal = $("#addReviewModal");
      $modal.find(".item").removeClass("error");

      $modal.find("[data-assistant-rating]").val(this.proposal.review.assistant_rating);
      $modal.find("[data-assistant-comment]").val(this.proposal.review.assistant_comment);
      $modal.find("[data-client-rating]").val(this.proposal.review.client_rating);
      $modal.find("[data-client-comment]").val(this.proposal.review.client_comment);

      let action = async function () {
        let reviewDetails = {
          assistant_rating: $modal.find("[data-assistant-rating]").val(),
          assistant_comment: $modal.find("[data-assistant-comment]").val(),
          client_rating: $modal.find("[data-client-rating]").val(),
          client_comment: $modal.find("[data-client-comment]").val(),
        };

        let hasError = false;
        $modal.find(".item").removeClass("error");

        if (reviewDetails.assistant_comment.length < 100) {
          $modal.find("[data-assistant-comment]").closest(".item").addClass("error");
          hasError = true;
        }

        if (reviewDetails.client_comment.length < 100) {
          $modal.find("[data-client-comment]").closest(".item").addClass("error");
          hasError = true;
        }

        if (hasError) {
          return false;
        }

        let updatedReview = await AdminManager.updateReview(
          $ctx.proposal.assistant.id,
          $ctx.proposal.project.id,
          reviewDetails
        );

        $ctx.proposal.review = updatedReview.review;
        $ctx.proposal.assistant = updatedReview.assistant;
        return true;
      };

      this.showModal(
        $modal,
        function () {
          $modal.find("[data-assistant-rating]").focus();
        },
        "Salvar",
        action,
        "Feedback atualizado.",
        "Ocorreu um erro ao atualizar o feedback.\nPor favor, tente novamente."
      );
    },

    activateReview() {
      let $ctx = this;

      let action = async function () {

        let updatedReview = await AdminManager.activateReview(
          $ctx.proposal.assistant.id,
          $ctx.proposal.project.id
        );

        $ctx.proposal.review.status = 2;
        $ctx.proposal.assistant = updatedReview.assistant;

        return true;
      };

      this.showConfirmation(
        "Ativar feedback",
        "Tem certeza que deseja ativar este feedback?",
        "Ativar",
        action,
        "O feedback foi ativado.",
        "Ocorreu um erro ativar o feedback.\nPor favor, tente novamente."
      );
    },

    inactivateReview() {
      let $ctx = this;

      let action = async function () {

        let updatedReview = await AdminManager.inactivateReview(
          $ctx.proposal.assistant.id,
          $ctx.proposal.project.id
        );

        $ctx.proposal.review.status = 3;
        $ctx.proposal.assistant = updatedReview.assistant;

        return true;
      };

      this.showConfirmation(
        "Desativar feedback",
        "Tem certeza que deseja desativar este feedback?",
        "Desativar",
        action,
        "O feedback foi desativado.",
        "Ocorreu um erro desativar o feedback.\nPor favor, tente novamente."
      );
    },
  },
};
</script>
