let config = {
    development: {
        apiHost: 'http://localhost:3000',
        assetsHost: "https://virtap.s3.amazonaws.com/test",
        videosHost: "https://streaming.virtap.com.br/aulas",
        coursesHost: "https://streaming.virtap.com.br/cursos",
        loginPage: "/login",
    },
    production: {
        apiHost: 'https://api.virtap.com.br',
        assetsHost: "https://virtap.s3.amazonaws.com/production",
        videosHost: "https://streaming.virtap.com.br/aulas",
        coursesHost: "https://streaming.virtap.com.br/cursos",
        loginPage: "/login",
    }
}

export default config[process.env.NODE_ENV];