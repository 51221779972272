<template>
  <div class="loading_container" v-if="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>

  <div class="main" v-else>
    <!-- Side Navbar -->
    <SideBar />
    <!-- Body-Content -->
    <div class="body-content">
      <!-- Top Navbar -->
      <TopBar />
      <!-- Body -->
      <div class="container-fluid body-container">
        <div class="alert alert-warning text-center" v-if="assistant.status === 1">
          Este perfil está aguardando moderação.
          <div class="admin-buttons-h mt-3">
            <a class="btn bg-success" @click="approveAssistant">Aprovar perfil</a>
            <a class="btn btn-cancel" @click="rejectAssistant">Reprovar perfil</a>
          </div>
        </div>
        <div class="alert alert-success text-center" v-else-if="assistant.status === 2">
          Este perfil está ativo.
          <div class="admin-buttons-h mt-3">
            <a class="btn btn-cancel" @click="inactivateAssistant">Desativar perfil</a>
          </div>
        </div>
        <div class="alert alert-danger text-center" v-else-if="assistant.status === 3">
          Este perfil está desativado.
          <div class="admin-buttons-h mt-3">
            <a class="btn bg-success" @click="activateAssistant">Ativar perfil</a>
          </div>
        </div>

        <div class="element">
          <div class="freelancer_profile">
            <div class="row">
              <div class="col-lg-12">
                <div class="talent_profile" v-if="assistant !== null">
                  <div class="talent-info">
                    <div class="freelancer_info">
                      <div class="freelancer">
                        <img class="freelancer_img" :src="getImageURL(assistant.picture)" v-if="assistant.picture"
                          alt="" />
                        <img class="freelancer_img empty-image" v-else alt="" />

                        <div class="info">
                          <div>
                            <a>
                              <h2>
                                {{ assistant.name }}
                              </h2>
                            </a>
                            <p>{{ assistant.title }}</p>
                          </div>
                          <div class="down-icons-list">
                            <div class="ex_icon" v-if="assistant.cert_av || assistant.cert_ap">
                              <span v-if="assistant.cert_av" class="cert_av"><i class="fa-solid fa-laptop icons"></i></span>
                              <span v-if="assistant.cert_ap" class="cert_ap"><i class="fa-solid fa-star icons"></i></span>
                              <!-- <span
                                  ><i class="fa-solid fa-trophy icons"></i
                                ></span> -->
                            </div>

                            <div class="star">                                
                                <i :class="star.class" class="icons" v-for="star in getRatingStars(assistant.feedback_rating)" :key="star.id"></i>
                                <span class="rating"> ({{ assistant.feedback_rating.toFixed(2) }})</span>
                            </div>
                          </div>
                          <p class="country">{{ assistant.location }}</p>
                        </div>
                      </div>
                      <a class="btn" @click="editAssistant()">Editar perfil</a>
                    </div>
                  </div>
                  <div class="keywords-group">
                    <div class="ky_btn" v-if="assistant.area_ap">
                      Assistência Pessoal
                    </div>
                    <div class="ky_btn" v-if="assistant.area_ae">
                      Secretariado Executivo
                    </div>
                    <div class="ky_btn" v-if="assistant.area_sa">
                      Suporte Administrativo
                    </div>
                    <div class="ky_btn" v-if="assistant.area_sr">
                      Secretariado remoto
                    </div>
                    <div class="ky_btn" v-if="assistant.area_si">
                      Suporte para Infoprodutores
                    </div>
                    <div class="ky_btn" v-if="assistant.area_ed">
                      Entrada de dados
                    </div>
                  </div>
                  <p class="mt-4 break-line">{{ assistant.description }}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="row seller_review">
            <div class="col-lg-12">
              <div class="post_project user mt-5">
                <div class="nav">
                  <h1>Reviews for this seller</h1>
                  <h1 class="ratting_number">
                    <svg
                      class="svg-inline--fa fa-star icons"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="star"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                      ></path></svg
                    >(550)
                  </h1>
                </div>
                <div class="proposals-list">
                  <div class="proposal">
                    <div class="user">
                      <div class="freelancer-img">
                        <img src="assets/img/assistant_img.png" alt="" />
                      </div>
                      <div class="proposal-info">
                        <p class="name">Name Here</p>
                        <div class="ratting mt-1">
                          <p class="star">
                            <svg
                              class="svg-inline--fa fa-star icons"
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="star"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 576 512"
                              data-fa-i2svg=""
                            >
                              <path
                                fill="currentColor"
                                d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                              ></path></svg
                            >
                            (5.0)
                          </p>
                          <p>
                            <img src="assets/img/country/BD_Flag.png" alt="" />
                            Bangladesh
                          </p>
                        </div>
                        <p class="mt-2">Highly skilled and talented.</p>
                      </div>
                    </div>
                    <div class="day">
                      <p>Published 1 week ago</p>
                    </div>
                  </div>
                  <div class="proposal">
                    <div class="user">
                      <div class="freelancer-img">
                        <img src="assets/img/assistant_img.png" alt="" />
                      </div>
                      <div class="proposal-info">
                        <p class="name">Name Here</p>
                        <div class="ratting mt-1">
                          <p class="star">
                            <svg
                              class="svg-inline--fa fa-star icons"
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="star"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 576 512"
                              data-fa-i2svg=""
                            >
                              <path
                                fill="currentColor"
                                d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                              ></path></svg
                            >
                            (5.0)
                          </p>
                          <p>
                            <img src="assets/img/country/BD_Flag.png" alt="" />
                            Bangladesh
                          </p>
                        </div>
                        <p class="mt-2">Highly skilled and talented.</p>
                      </div>
                    </div>
                    <div class="day">
                      <p>Published 1 week ago</p>
                    </div>
                  </div>
                  <div class="proposal">
                    <div class="user">
                      <div class="freelancer-img">
                        <img src="assets/img/assistant_img.png" alt="" />
                      </div>
                      <div class="proposal-info">
                        <p class="name">Name Here</p>
                        <div class="ratting mt-1">
                          <p class="star">
                            <svg
                              class="svg-inline--fa fa-star icons"
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="star"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 576 512"
                              data-fa-i2svg=""
                            >
                              <path
                                fill="currentColor"
                                d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                              ></path></svg
                            >
                            (5.0)
                          </p>
                          <p>
                            <img src="assets/img/country/BD_Flag.png" alt="" />
                            Bangladesh
                          </p>
                        </div>
                        <p class="mt-2">Highly skilled and talented.</p>
                      </div>
                    </div>
                    <div class="day">
                      <p>Published 1 week ago</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>

        <div style="margin-top: 30px">
          <div class="tab">
            <h1>Plano</h1>
            <a class="btn btn-small" @click="syncSubscription()"><i class="fa fa-gear" style="padding-right: 5px"></i>
              Sincronizar</a>
          </div>
          <div class="description-part section plan">
            <div v-if="assistant.plan === 'Free'">
              Plano atual: <span class="btn bg-warning btn-small"
                style="margin-left: 5px; cursor: default">Gratuito</span><br />
            </div>
            <div v-else-if="assistant.plan === 'Starter'">
              Plano atual: <span class="btn bg-success btn-small"
                style="margin-left: 5px; cursor: default">Starter</span><br />
            </div>
            <div v-else>
              Plano atual: <span class="btn bg-dark btn-small"
                style="margin-left: 5px; cursor: default">{{ assistant.plan }}</span><br />
            </div>
          </div>

        </div>


        <p class="mt-4 text-muted fw-bold">Propostas</p>

        <div v-if="!assistant.proposals || assistant.proposals.length === 0">
          <p>Nenhuma proposta foi enviada por este perfil de assistente.</p>
        </div>
        <div class="active-project" style="padding:0" v-else>
          <a class="card" style="position: static" v-for="proposal in assistant.proposals" :key="proposal.project_id"
            :href="'/proposta/' + assistant.id + '/' + proposal.project_id">
            <div class="name">
              <p>
                Assistente <br />
                <span>{{ assistant.name }}</span>
              </p>
            </div>
            <div class="date">
              <p>
                Data de registro <br />
                <span>{{
                  formatTimestamp(proposal.registration_timestamp)
                }}</span>
              </p>
            </div>
            <div class="count">
              <p>
                Tem feedback? <br />
                <span :class="{'text-success': proposal.assistant_rating}">{{ proposal.assistant_rating ? 'Sim' : 'Não' }}</span>
              </p>
            </div>
            <div class="status">
              <p>
                Status <br />
                <span :class="getTextStatusClass(proposal.status)">
                  {{ formatAssistantStatus(proposal.status) }}</span>
              </p>
            </div>
          </a>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "../SideBar.vue";
import TopBar from "../TopBar.vue";
import AdminManager from "../../../js/services/admin.js";
import StringUtils from "../../../js/utils/string.js";
import Config from "../../../../resources/config.js";

export default {
  components: { SideBar, TopBar },

  created() {
    this.$store.commit("setActiveSection", "AssistantPage");
    this.loadAssistantProfile();
  },

  data() {
    return {
      assistant: null,
    };
  },

  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },

  methods: {
    async loadAssistantProfile() {
      this.$store.commit("setLoading", true);

      try {
        let response = await AdminManager.getAssistant(
          this.$route.params.assistantId
        );
        this.profile = response.profile;
        this.assistant = response.assistant;
        this.$store.commit("setActiveUser", this.profile);
        this.$store.commit("setLoading", false);
      } catch (error) {
        this.handleAPIError(
          error,
          "assistente/" + this.$route.params.assistantId
        );
      }
    },

    editAssistant() {
      let $ctx = this;
      let $modal = $("#editAssistantModal");
      let $profileImage = $("#editAssistantProfileImg");
      let $btnRemovePhoto = $modal.find("[data-remove-photo]");
      let $name = $modal.find("[data-assistant-name]");
      let $plan = $modal.find("[data-assistant-plan]");
      let $title = $modal.find("[data-assistant-title]");
      let $description = $modal.find("[data-assistant-description]");
      let $chkAreas = $("#areasAtuacao");
      let $chkCerts = $("#certificacoes");

      $modal.find("#finput_assistant").val("");
      $modal.find(".item").removeClass("error");
      $name.val($ctx.assistant.name);
      $plan.val($ctx.assistant.plan);
      $title.val($ctx.assistant.title);
      $description.val($ctx.assistant.description);

      $modal.find("[data-assistant-email]").val($ctx.assistant.email_address);

      $modal.find("input[type=checkbox]").prop("checked", false);

      if ($ctx.assistant.cert_av) {
        $chkCerts
          .find("input[type=checkbox][value='ASSISTENTE_VIRTUAL'")
          .prop("checked", true);
      }

      if ($ctx.assistant.cert_ap) {
        $chkCerts
          .find("input[type=checkbox][value='ASSISTENTE_PESSOAL'")
          .prop("checked", true);
      }

      if ($ctx.assistant.area_ap) {
        $chkAreas
          .find("input[type=checkbox][value='ASSISTENCIA_PESSOAL'")
          .prop("checked", true);
      }

      if ($ctx.assistant.area_ae) {
        $chkAreas
          .find("input[type=checkbox][value='SECRETARIADO_EXECUTIVO'")
          .prop("checked", true);
      }

      if ($ctx.assistant.area_sa) {
        $chkAreas
          .find("input[type=checkbox][value='SUPORTE_ADMINISTRATIVO'")
          .prop("checked", true);
      }

      if ($ctx.assistant.area_sr) {
        $chkAreas
          .find("input[type=checkbox][value='SECRETARIADO_REMOTO'")
          .prop("checked", true);
      }

      if ($ctx.assistant.area_si) {
        $modal
          .find("input[type=checkbox][value='SUPORTE_PARA_INFOPRODUTORES'")
          .prop("checked", true);
      }

      if ($ctx.assistant.area_ed) {
        $chkAreas
          .find("input[type=checkbox][value='ENTRADA_DE_DADOS'")
          .prop("checked", true);
      }

      if (this.assistant.picture) {
        $profileImage.show();
        $profileImage.attr("src", this.getImageURL(this.assistant.picture));
        $btnRemovePhoto.show();
      } else {
        $btnRemovePhoto.hide();
        $profileImage.removeAttr("src");
      }
      $profileImage.data("file", null);

      $btnRemovePhoto.off().on("click", function () {
        $btnRemovePhoto.hide();
        $profileImage.removeAttr("src");
        $profileImage.data("file", null);
        $profileImage.data("removeFile", true);
      });

      let action = async function () {
        let selectedAreas = [];
        let selectedCertifications = [];

        $chkAreas.find("input[type=checkbox]:checked").each(function (i, e) {
          selectedAreas.push($(e).attr("value"));
        });

        $chkCerts.find("input[type=checkbox]:checked").each(function (i, e) {
          selectedCertifications.push($(e).attr("value"));
        });

        let assistantDetails = {
          name: $name.val(),
          title: $title.val(),
          description: $description.val(),
          plan: $plan.val(),
          expertise_areas: selectedAreas,
          certifications: selectedCertifications,
        };

        if ($profileImage.data("file")) {
          assistantDetails.picture = $profileImage.data("file");
        } else if ($profileImage.data("removeFile")) {
          assistantDetails.removePicture = true;
        }

        let hasError = false;
        $modal.find(".item").removeClass("error");

        if (assistantDetails.name.length < 10) {
          $name.closest(".item").addClass("error");
          hasError = true;
        }

        if (assistantDetails.title.length < 10) {
          $title.closest(".item").addClass("error");
          hasError = true;
        }

        if (assistantDetails.description.length < 50) {
          $description.closest(".item").addClass("error");
          hasError = true;
        }

        if (
          assistantDetails.expertise_areas.length === 0 ||
          assistantDetails.expertise_areas.length > 3
        ) {
          $chkAreas.closest(".item").addClass("error");
          hasError = true;
        }

        if (hasError) {
          return false;
        }

        let updatedAssistant = await AdminManager.updateAssistant(
          $ctx.assistant.id,
          assistantDetails
        );

        $ctx.assistant = updatedAssistant;
        return true;
      };

      this.showModal(
        $modal,
        function () {
          $modal.find("[data-assistant-name]").focus();
        },
        "Salvar",
        action,
        "Perfil atualizado.",
        "Ocorreu um erro editar o perfil.\nPor favor, tente novamente."
      );
    },

    approveAssistant() {
      let $ctx = this;

      let action = async function () {
        let assistantDetails = {
          status: 2,
        };

        let updatedAssistant = await AdminManager.updateAssistant(
          $ctx.assistant.id,
          assistantDetails
        );

        $ctx.assistant = updatedAssistant;
        return true;
      };

      this.showConfirmation(
        "Aprovar perfil",
        "Tem certeza que deseja aprovar este perfil?",
        "Aprovar",
        action,
        "O perfil foi aprovado.",
        "Ocorreu um erro ao aprovar o perfil.\nPor favor, tente novamente."
      );
    },

    activateAssistant() {
      let $ctx = this;

      let action = async function () {
        let assistantDetails = {
          status: 2,
        };

        let updatedAssistant = await AdminManager.updateAssistant(
          $ctx.assistant.id,
          assistantDetails
        );

        $ctx.assistant = updatedAssistant;
        return true;
      };

      this.showConfirmation(
        "Ativar perfil",
        "Tem certeza que deseja ativar este perfil?",
        "Ativar",
        action,
        "O perfil foi ativado.",
        "Ocorreu um erro ao ativar o perfil.\nPor favor, tente novamente."
      );
    },

    rejectAssistant() {
      let $ctx = this;

      let action = async function () {
        let assistantDetails = {
          status: 3,
        };

        let updatedAssistant = await AdminManager.updateAssistant(
          $ctx.assistant.id,
          assistantDetails
        );

        $ctx.assistant = updatedAssistant;
        return true;
      };

      this.showConfirmation(
        "Reprovar perfil",
        "Tem certeza que deseja reprovar este perfil ?",
        "Reprovar",
        action,
        "O perfil foi reprovado.",
        "Ocorreu um erro ao reprovar este perfil.\nPor favor, tente novamente."
      );
    },

    inactivateAssistant() {
      let $ctx = this;

      let action = async function () {
        let assistantDetails = {
          status: 3,
        };

        let updatedAssistant = await AdminManager.updateAssistant(
          $ctx.assistant.id,
          assistantDetails
        );

        $ctx.assistant = updatedAssistant;
        return true;
      };

      this.showConfirmation(
        "Desativar perfil",
        "Tem certeza que deseja desativar este perfil?",
        "Desativar",
        action,
        "O perfil foi desativado.",
        "Ocorreu um erro ao desativar este perfil.\nPor favor, tente novamente."
      );
    },

    syncSubscription() {
      let $ctx = this;

      let action = async function () {
        let updatedAssistant = await AdminManager.syncSubscription(
          $ctx.assistant.id
        );

        $ctx.assistant = updatedAssistant;
        return true;
      };

      this.showConfirmation(
        "Sincronizar assinatura",
        "Tem certeza que deseja sincronizar a assinatura?",
        "Sincronizar",
        action,
        "O perfil foi sincronizado.",
        "Ocorreu um erro ao sincronizar este perfil.\nPor favor, tente novamente."
      );
    }
  },
};
</script>
