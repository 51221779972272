<template>
  <div class="top_navabr">
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <button class="navbar-toggler" id="toggleButton" onclick="toggle()">
          <span class="navbar-toggler-icon"></span>
        </button>
        <!-- <div class="top-nav-content">
          <a
            href="#"
            class="notification"
            data-bs-toggle="modal"
            data-bs-target="#notification"
            ><i class="fa-solid fa-bell icons"></i
          ></a>
        </div> -->
        <div id="navbarNav" style="display: none">
          <ul class="navbar-nav nav-2">
            <li>
              <a
                class="nav-link"
                :class="{ active: this.section === 'DashboardPage' }"
                href="/"
                ><i class="fa-solid fa-house icons"></i>Dashboard</a
              >
            </li>
            <li>
              <a
                class="nav-link"
                href="/assistentes"
                :class="{ active: this.section === 'AssistantListPage' || this.section === 'AssistantPage' }"
                ><i class="fa-solid fa-user-graduate icons"></i>Assistentes</a
              >
            </li>
            <li>
              <a
                class="nav-link"
                href="/clientes"
                :class="{ active: this.section === 'ClientListPage' || this.section === 'ClientPage' }"
                ><i class="fa-solid fa-user icons"></i>Clientes</a
              >
            </li>
            <li>
              <a
                class="nav-link"
                href="/projetos"
                :class="{ active: this.section === 'ProjectListPage' || this.section === 'ProjectPage' }"
                ><i class="fa-solid fa-briefcase icons"></i>Projetos</a
              >
            </li>
            <li>
              <a
                class="nav-link"
                href="/propostas"
                :class="{ active: this.section === 'ProposalListPage' || this.section === 'ProposalPage' }"
                ><i class="fa-regular fa-file-lines icons"></i>Propostas</a
              >
            </li>
            <li>
              <a
                class="nav-link"
                href="/videos"
                :class="{ active: this.section === 'VideoListPage' || this.section === 'VideoPage' }"
                ><i class="fa-brands fa-youtube icons"></i>Vídeos</a
              >
            </li>


            <li>
              <a class="nav-link" href="/cursos"
                :class="{ active: this.section === 'CourseListPage' || this.section === 'ClassPage' }"><i
                  class="fa-solid fa-graduation-cap icons"></i>Cursos</a>
            </li>

            
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  computed: {
    section() {
      return this.$store.state.activeSection;
    },
  },

  methods: {},
};
</script>
