const state = {
    loading: false,
    error: null,
    activeSection: "",
    activeUser: null,
    activeProjectList: null,
    title: "",
};

const mutations = {
    setLoading(state, loading) {
        state.loading = loading;
    },

    setError(state, error) {
        state.error = error;
    },


    setActiveSection(state, activeSection) {
        state.activeSection = activeSection;
    },

    setActiveUser(state, activeUser) {
        state.activeUser = activeUser;
    },

    setActiveProjectList(state, activeProjectList) {
        state.activeProjectList = activeProjectList;
    }
};

const getters = {}

const actions = {}

export default {
    state,
    getters,
    mutations,
    actions
}