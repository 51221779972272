<template>
  <div class="loading_container" v-if="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
  <div class="main" v-else>
    <!-- Side Navbar -->
    <SideBar />
    <!-- Body-Content -->
    <div class="body-content">
      <!-- Top Navbar -->
      <TopBar />
      <!-- Body -->
      <div class="container-fluid body-container">
        <div class="element">
          <div class="search-box-container">
            <div class="nav" style="border-radius: 8px 8px 0 0">
              <h1>
                Propostas
                <span v-if="!listLoading && !listError">({{ proposalCount }})</span>
              </h1>
              <select
                class="form-select"
                @change="selectNewStatus($event)"
                :disabled="listLoading"
              >
                <option value="">Todos</option>
                <option value="1">Em análise</option>
                <option value="2">Ativos</option>
                <option value="3">Desativados</option>
              </select>
            </div>
          </div>
          <div class="project-list">
            <div
              class="post_project post_project-active"
              style="padding: 20px; height: auto"
              :class="{ nopro: proposals && proposals.length === 0 }"
            >
              <div class="loading_container" v-if="listLoading" style="height: 200px">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only"></span>
                </div>
              </div>
              <div v-else-if="listError" class="create-project">
                <div class="card">
                  <div class="card-body">
                    <h2 class="text-danger">Erro</h2>
                    <p class="text-danger">
                      Ocorreu um erro ao atualizar a lista de propostas.<br />
                      Por favor, tente novamente.
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="create-project"
                v-else-if="!proposals || proposals.length === 0"
              >
                <div class="card">
                  <img src="assets/img/project.png" alt="" />
                  <div class="card-body">
                    <h2>Nenhuma proposta encontrada</h2>
                  </div>
                </div>
              </div>
              <div class="active-project" style="padding: 0" v-else>
                <a
                  class="card"
                  style="position: static"
                  v-for="proposal in proposals"
                  :key="proposal.id"
                  :href="'/proposta/' + proposal.assistant_id + '/' + proposal.project_id"
                >
                  <div class="name">
                    <p>
                      Assistente <br />
                      <span>{{ proposal.name }}</span>
                    </p>
                  </div>
                  <div class="date">
                    <p>
                      Data de registro <br />
                      <span>{{ formatTimestamp(proposal.registration_timestamp) }}</span>
                    </p>
                  </div>
                  <div class="count">
                    <p>
                      Tem feedback? <br />
                      <span :class="{ 'text-success': proposal.assistant_rating }">{{
                        proposal.assistant_rating ? "Sim" : "Não"
                      }}</span>
                    </p>
                  </div>
                  <div class="status">
                    <p>
                      Status <br />
                      <span :class="getTextStatusClass(proposal.status)">
                        {{ formatProposalStatus(proposal.status) }}</span
                      >
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import SideBar from "../SideBar.vue";
import TopBar from "../TopBar.vue";
import AdminManager from "../../../js/services/admin.js";
import StringUtils from "../../../js/utils/string.js";
import Config from "../../../../resources/config.js";

export default {
  components: { SideBar, TopBar },

  created() {
    this.$store.commit("setActiveSection", "ProposalListPage");
    this.loadClients();
  },

  data() {
    return {
      proposals: null,
      listLoading: false,
      listError: null,
    };
  },

  computed: {
    loading() {
      return this.$store.state.loading;
    },

    proposalCount() {
      if (this.proposals) {
        return this.proposals.length;
      }
      return 0;
    },
  },

  methods: {
    async loadClients() {
      this.$store.commit("setLoading", true);
      try {
        let response = await AdminManager.getProposals();
        let profile = response.profile;
        this.proposals = response.proposals;
        this.$store.commit("setActiveUser", profile);
        this.$store.commit("setLoading", false);
      } catch (error) {
        this.handleAPIError(error, "propostas");
      }
    },

    async selectNewStatus(event) {
      let selectedStatus = event.target.value;
      this.listLoading = true;
      this.listError = null;
      try {
        let response = await AdminManager.getProposals(selectedStatus);
        this.proposals = response.proposals;
      } catch (err) {
        console.log(err);
        this.listError = err;
      } finally {
        this.listLoading = false;
      }
    },
  },
};
</script>
