<template>
  <div class="loading_container" v-if="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>

  <div class="main" v-else>
    <!-- Side Navbar -->
    <SideBar />
    <!-- Body-Content -->
    <div class="body-content">
      <!-- Top Navbar -->
      <TopBar />
      <!-- Body -->
      <div class="container-fluid body-container">
        <div class="row">
          <div class="col-lg-12">
            <h1>Estatísticas</h1>
            <div class="row">
              <div class="col-md-4 col-sm-12">
                <div class="stati">
                  <div>
                    <div><i class="fa-solid fa-graduation-cap" /> Assistentes</div>
                    <h2>{{ stats.assistants_count }}</h2>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="stati">
                  <div>
                    <div><i class="fa-solid fa-user" /> Clientes</div>
                    <h2>{{ stats.clients_count }}</h2>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="stati">
                  <div>
                    <div><i class="fa-solid fa-briefcase" /> Projetos ativos</div>
                    <h2>{{ stats.projects_count }}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-lg-12">
            <h1>Relatórios</h1>

            <div class="row">
              <div class="col-md-4 col-sm-12">
                <div class="stati">
                  <button class="btn btn-small" @click="allClients()">Lista de clientes</button>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="stati">
                  <button class="btn btn-small" @click="allProjects()">Lista de projetos</button>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="stati">
                  <button class="btn btn-small" @click="allReleasedContacts()">Lista de propostas liberadas</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "../SideBar.vue";
import TopBar from "../TopBar.vue";
import AdminManager from "../../../js/services/admin.js";
import StringUtils from "../../../js/utils/string.js";
import Config from "../../../../resources/config.js";

export default {
  components: { SideBar, TopBar },

  created() {
    this.$store.commit("setActiveSection", "DashboardPage");
    this.$store.commit("setActiveProjectList", null);
    this.loadAdminData();
  },

  data() {
    return {
      profile: null,
      stats: null,
    };
  },

  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },

  methods: {
    async loadAdminData() {
      this.$store.commit("setLoading", true);

      try {
        let adminData = await AdminManager.getAdminData();
        this.profile = adminData.profile;

        if (!this.profile) {
          window.location = "/cadastro";
        } else {
          this.stats = adminData.stats;
          this.$store.commit("setActiveUser", this.profile);
          this.$store.commit("setLoading", false);
        }
      } catch (error) {
        this.handleAPIError(error, null);
      }
    },

    allClients() {
      let url = `${Config.apiHost}/admin/reports/clients`;
      window.open(url);
    },

    allProjects() {
      let url = `${Config.apiHost}/admin/reports/projects`;
      window.open(url);
    },

    allReleasedContacts() {
      let url = `${Config.apiHost}/admin/reports/released-contacts`;
      window.open(url);
    },
  },
};
</script>
