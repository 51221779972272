<template>
  <div class="loading_container" v-if="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>

  <div class="main" v-else>
    <!-- Side Navbar -->
    <SideBar />
    <!-- Body-Content -->
    <div class="body-content">
      <!-- Top Navbar -->
      <TopBar />
      <!-- Body -->
      <div class="container-fluid body-container">
        <div v-if="course !== null">
          <div class="center-block" v-if="classObj === null">
            <div>
              <div class="alert alert-danger mb-0">
                A aula que você tentou acessar não está disponível.
              </div>
            </div>
          </div>
          <section class="video_play_page" v-else>
            <div style="width: 66.66666667%">
              <h1
                class="video-title mb-2"
                style="width: auto; max-width: 960px; text-align: left"
              >
                {{ course.title }}
                <button class="btn btn-small" @click="editCourse()">Editar</button><br />
                <span class="text-muted"
                  >{{ classObj.title }}
                  <button class="btn btn-small" @click="editClass(classObj)">
                    Editar
                  </button></span
                >
              </h1>
            </div>
            <div class="row gy-2 mt-0">
              <div class="col-lg-8">
                <div class="video-container">
                  <video-js
                    id="player"
                    controls
                    controlsList="nodownload"
                    fluid="true"
                    class="video-js vjs-theme-city vjs-big-play-centered"
                  >
                    <source
                      :src="getCourseClassURL(this.course, this.module, this.classObj)"
                      type="application/x-mpegURL"
                      data-setup='{ "playbackRates": [0.5, 1, 1.5, 2] }'
                    />
                  </video-js>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="accordion accordion-flush" id="modules" :key="course.id">
                  <div
                    class="accordion-item"
                    v-for="module in course.modules"
                    :key="course.id + '_module_' + module.id"
                  >
                    <h2 class="accordion-header" :id="'module_' + module.id">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        :data-bs-target="'#flush-collapse' + module.id"
                        aria-expanded="false"
                        :aria-controls="'#flush-collapse' + module.id"
                      >
                        <span class="d-flex">
                          <span
                            >{{ module.title }}
                            <span class="text-muted">
                              <button
                                class="btn btn-small"
                                @click.prevent="editModule(module)"
                              >
                                Editar
                              </button></span
                            ></span
                          >
                        </span>
                      </button>
                    </h2>
                    <div
                      :id="'flush-collapse' + module.id"
                      class="accordion-collapse"
                      :class="{
                        show: classObj.course_module_id === module.id,
                        collapse: classObj.course_module_id !== module.id,
                      }"
                      :aria-labelledby="'module_' + module.id"
                      data-bs-parent="#modules"
                    >
                      <div class="accordion-body">
                        <p
                          class="d-flex nav-link"
                          :class="{ active: c.id === classObj.id }"
                          v-for="c in module.classes"
                          :key="course.id + '_class_' + c.id"
                          @click="goToClass(c)"
                        >
                          <i class="fa-regular fa-circle"></i>
                          <span>{{ c.title }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-0">
              <div class="video-details mt-3">
                <div
                  class="d-flex mb-2"
                  style="
                    font-size: 14.2px;
                    gap: 20px;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <span class="fw-bold">{{
                    formatPlural(classObj.views, "visualização", "visualizações")
                  }}</span>
                </div>
                <div class="wrap-box short">
                  <p class="break-word" style="font-size: 14.2px">
                    {{ classObj.description }}
                  </p>
                  <label></label>
                </div>
              </div>
            </div>
            <div class="mt-4">
              <h3 class="comments-title">Comentários ({{ classObj.active_comments }})</h3>
              <hr
                style="margin-bottom: 0px; border-color: var(--border-color); opacity: 1"
                v-if="comments.length > 0"
              />
              <div class="alert alert-warning mt-2" v-if="comments.length === 0">
                Nenhum comentário sobre essa aula ainda.
              </div>
              <div class="comments" v-else>
                <div
                  class="box"
                  v-for="comment in comments"
                  :key="comment.id"
                  :id="comment.id"
                >
                  <img :src="getImageURL(comment.picture)" v-if="comment.picture" />
                  <img class="empty-image" v-else />
                  <div class="c_text_area">
                    <p class="name">
                      {{ comment.name ? comment.name : "Virtap"
                      }}<span>{{
                        formatElapsedTime(comment.registration_timestamp)
                      }}</span>
                    </p>
                    <div class="wrap-box short">
                      <p
                        class="break-word dsc"
                        v-html="formatMultilineText(comment.comment)"
                      ></p>
                      <label></label>
                    </div>
                    <div v-if="comment.status === 1">
                      <p class="under-review">
                        Este comentário está aguardando moderação e encontra-se invisível
                        no momento.
                      </p>
                      <div class="mt-2">
                        <button
                          class="btn btn-small bg-success"
                          style="margin-right: 5px"
                          @click="approveComment(comment)"
                        >
                          Aprovar
                        </button>
                        <button
                          class="btn btn-small bg-danger"
                          style="margin-right: 5px"
                          @click="reproveComment(comment)"
                        >
                          Reprovar
                        </button>
                        <button class="btn btn-small" @click="editClassComment(comment)">
                          Editar
                        </button>
                      </div>
                    </div>
                    <div v-if="comment.status === 2">
                      <div class="mt-2">
                        <button
                          class="btn btn-small bg-danger"
                          style="margin-right: 5px"
                          @click="deactivateComment(comment)"
                        >
                          Desativar
                        </button>
                        <button class="btn btn-small"  style="margin-right: 5px" @click="editClassComment(comment)">
                          Editar
                        </button>
                        <button
                          class="btn btn-small"
                          :class="{'bg-success': comment.children_count === 0, 'bg-warning': comment.children_count > 0}"
                         
                          @click="editReply(comment)"
                        >

                          {{comment.children_count === 0 ? 'Adicionar resposta' : 'Editar resposta'}}
                        </button> 

                      </div>
                    </div>
                    <div v-else-if="comment.status === 3">
                      <p class="inactive">Este comentário foi desativado.</p>
                      <div class="mt-2">
                        <button
                          class="btn btn-small bg-success"
                          style="margin-right: 5px"
                          @click="activateComment(comment)"
                        >
                          Ativar
                        </button>
                        <button class="btn btn-small" @click="editClassComment(comment)">
                          Editar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="page_number">
              <button
                id="fetchMoreComments"
                class="btn see_more btn-small"
                :class="{ disabled: loadingMore }"
                @click="fetchMore()"
                v-if="hasMore"
              >
                {{ loadingMore ? "Carregando..." : "Mostrar mais" }}
              </button>
            </div>
          </section>
        </div>
        <div class="center-block" v-else>
          <div>
            <div class="alert alert-danger mb-0">Este curso não está disponível.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "../SideBar.vue";
import TopBar from "../TopBar.vue";
import AdminManager from "../../../js/services/admin.js";
import StringUtils from "../../../js/utils/string.js";
import Config from "../../../../resources/config.js";

const RESULT_COUNT = 1000;

export default {
  components: { SideBar, TopBar },

  created() {
    this.$store.commit("setActiveSection", "ClassPage");
    this.loadClass();
  },

  data() {
    return {
      course: null,
      module: null,
      classObj: null,
      posting: false,
      comments: [],
      loadingMore: false,
      hasMore: true,
    };
  },

  computed: {
    loading() {
      return this.$store.state.loading;
    },
    activeUser() {
      return this.$store.state.activeUser;
    },
  },

  methods: {
    async loadClass() {
      let $ctx = this;
      this.$store.commit("setLoading", true);
      try {
        let response = await AdminManager.getClass(
          this.$route.params.courseId,
          this.$route.params.classId,
          RESULT_COUNT
        );
        this.course = response.course;
        this.module = response.module;
        this.classObj = response.class;
        this.comments = response.comments;
        this.hasMore = this.comments.length >= RESULT_COUNT;
        this.$store.commit("setActiveUser", response.assistant);
        this.$store.commit("setLoading", false);
        this.applyTruncation();
        if (!this.course) {
          return;
        }
        this.$nextTick(() => {
          let player = videojs("player", {
            playbackRates: [0.5, 1, 1.5, 2],
            html5: {
              hls: {
                //  withCredentials: true,
              },
            },
          });

          player.ready(function () {
            player.play();
          });
        });
      } catch (error) {
        this.handleAPIError(
          error,
          "curso/" + this.$route.params.courseId + "/" + this.$route.params.classId
        );
      }
    },

    goToClass(classObj) {
      location.href = "/curso/" + this.course.id + "/" + classObj.id;
    },

    async approveComment(comment) {
      let action = async function () {
        let commentDetails = {
          status: 2,
        };

        let updatedComment = await AdminManager.updateClassComment(
          comment.id,
          commentDetails
        );

        comment.status = updatedComment.status;
        return true;
      };

      this.showConfirmation(
        "Aprovar comentário",
        "Tem certeza que deseja aprovar este comentário?",
        "Aprovar",
        action,
        "Comentário aprovado",
        "Ocorreu um erro ao aprovar o comentário.\nPor favor, tente novamente."
      );
    },

    async activateComment(comment) {
      let action = async function () {
        let commentDetails = {
          status: 2,
        };

        let updatedComment = await AdminManager.updateClassComment(
          comment.id,
          commentDetails
        );

        comment.status = updatedComment.status;
        return true;
      };

      this.showConfirmation(
        "Ativar comentário",
        "Tem certeza que deseja ativar este comentário?",
        "Ativar",
        action,
        "Comentário ativado",
        "Ocorreu um erro ao ativar o comentário.\nPor favor, tente novamente."
      );
    },

    async reproveComment(comment) {
      let action = async function () {
        let commentDetails = {
          status: 3,
        };

        let updatedComment = await AdminManager.updateClassComment(
          comment.id,
          commentDetails
        );

        comment.status = updatedComment.status;
        return true;
      };

      this.showConfirmation(
        "Reprovar comentário",
        "Tem certeza que deseja reprovar este comentário?",
        "Reprovar",
        action,
        "Comentário reprovado",
        "Ocorreu um erro ao reprovar o comentário.\nPor favor, tente novamente."
      );
    },

    async deactivateComment(comment) {
      let action = async function () {
        let commentDetails = {
          status: 3,
        };

        let updatedComment = await AdminManager.updateClassComment(
          comment.id,
          commentDetails
        );

        comment.status = updatedComment.status;
        return true;
      };

      this.showConfirmation(
        "Desativar comentário",
        "Tem certeza que deseja desativar este comentário?",
        "Desativar",
        action,
        "Comentário desativado",
        "Ocorreu um erro ao desativar o comentário.\nPor favor, tente novamente."
      );
    },

    async editCourse() {
      let $ctx = this;
      let $modal = $("#editCourseModal");
      $modal.find(".item").removeClass("error");

      let action = async function () {
        let courseDetails = {
          title: $modal.find("[data-course-title]").val(),
          path: $modal.find("[data-course-path]").val(),
          status: $modal.find("[data-course-status]").val(),
        };

        let hasError = false;
        $modal.find(".item").removeClass("error");

        if (courseDetails.title.length < 10) {
          $modal.find("[data-course-title]").closest(".item").addClass("error");
          hasError = true;
        }
        if (courseDetails.path.length < 10) {
          $modal.find("[data-course-path]").closest(".item").addClass("error");
          hasError = true;
        }
        if (hasError) {
          return false;
        }

        let updatedCourse = await AdminManager.updateCourse(
          $ctx.course.id,
          courseDetails
        );

        $ctx.course.title = updatedCourse.title;
        $ctx.course.path = updatedCourse.path;
        $ctx.course.status = updatedCourse.status;
        return true;
      };

      $modal.find("[data-course-title]").val($ctx.course.title);
      $modal.find("[data-course-path]").val($ctx.course.path);
      $modal.find("[data-course-status]").val($ctx.course.status);

      this.showModal(
        $modal,
        function () {
          $modal.find("[data-course-title]").focus();
        },
        "Salvar",
        action,
        "Curso atualizado.",
        "Ocorreu um erro editar o curso.\nPor favor, tente novamente."
      );
    },

    async editModule(module) {
      let $ctx = this;
      let $modal = $("#editModuleModal");
      $modal.find(".item").removeClass("error");

      let action = async function () {
        let moduleDetails = {
          title: $modal.find("[data-module-title]").val(),
          path: $modal.find("[data-module-path]").val(),
          number: $modal.find("[data-module-number]").val(),
        };

        let hasError = false;
        $modal.find(".item").removeClass("error");

        if (moduleDetails.title.length < 10) {
          $modal.find("[data-module-title]").closest(".item").addClass("error");
          hasError = true;
        }
        if (moduleDetails.path.length < 10) {
          $modal.find("[data-module-path]").closest(".item").addClass("error");
          hasError = true;
        }
        if (hasError) {
          return false;
        }

        let updatedCourse = await AdminManager.updateModule(module.id, moduleDetails);

        $ctx.course = updatedCourse;

        return true;
      };

      $modal.find("[data-module-title]").val(module.title);
      $modal.find("[data-module-path]").val(module.path);
      $modal.find("[data-module-number]").val(module.number);

      this.showModal(
        $modal,
        function () {
          $modal.find("[data-module-title]").focus();
        },
        "Salvar",
        action,
        "Módulo atualizado.",
        "Ocorreu um erro editar o módulo.\nPor favor, tente novamente."
      );
    },

    async editClass(classObj) {
      let $ctx = this;
      let $modal = $("#editClassModal");
      $modal.find(".item").removeClass("error");

      let action = async function () {
        let classDetails = {
          title: $modal.find("[data-class-title]").val(),
          path: $modal.find("[data-class-path]").val(),
          number: $modal.find("[data-class-number]").val(),
        };

        let hasError = false;
        $modal.find(".item").removeClass("error");

        if (classDetails.title.length < 10) {
          $modal.find("[data-class-title]").closest(".item").addClass("error");
          hasError = true;
        }
        if (classDetails.path.length < 10) {
          $modal.find("[data-class-path]").closest(".item").addClass("error");
          hasError = true;
        }
        if (hasError) {
          return false;
        }

        let updatedCourse = await AdminManager.updateClass(
          $ctx.course.id,
          classObj.id,
          classDetails
        );

        for (let m of updatedCourse.modules) {
          let s = 0;
          for (let c of m.classes) {
            if (c.id === $ctx.classObj.id) {
              $ctx.classObj = c;
              break;
            }
          }
        }

        $ctx.course = updatedCourse;

        return true;
      };

      $modal.find("[data-class-title]").val(classObj.title);
      $modal.find("[data-class-path]").val(classObj.path);
      $modal.find("[data-class-number]").val(classObj.number);

      this.showModal(
        $modal,
        function () {
          $modal.find("[data-class-title]").focus();
        },
        "Salvar",
        action,
        "Aula atualizada.",
        "Ocorreu um erro editar a aula.\nPor favor, tente novamente."
      );
    },

    async editClassComment(comment) {
      let $ctx = this;
      let $modal = $("#editClassCommentModal");
      $modal.find(".item").removeClass("error");

      let action = async function () {
        let commentDetails = {
          comment: $modal.find("[data-comment]").val(),
        };

        let hasError = false;
        $modal.find(".item").removeClass("error");

        if (commentDetails.comment.length < 10) {
          $modal.find("[data-comment]").closest(".item").addClass("error");
          hasError = true;
        }

        if (hasError) {
          return false;
        }

        let updatedComment = await AdminManager.updateClassComment(
          comment.id,
          commentDetails
        );

        comment.comment = updatedComment.comment;
        return true;
      };

      $modal.find("[data-comment]").val(comment.comment);

      this.showModal(
        $modal,
        function () {
          $modal.find("[data-comment]").focus();
        },
        "Salvar",
        action,
        "Comentário atualizado.",
        "Ocorreu um erro editar o comentário.\nPor favor, tente novamente."
      );
    },

    async editReply(comment) {
      let $ctx = this;
      let $modal = $("#editReplyModal");
      $modal.find(".item").removeClass("error");
      $modal.find("[data-loading]").show();
      $modal.find("[data-content]").hide();
      $modal.find(".btn-close").addClass("disabled");
      $modal.find("[data-loading-error]").html("").hide();

      let action = async function () {
        let commentDetails = {
          comment: $modal.find("[data-comment]").val(),
        };

        let hasError = false;
        $modal.find(".item").removeClass("error");

        if (commentDetails.comment.length < 10) {
          $modal.find("[data-comment]").closest(".item").addClass("error");
          hasError = true;
        }

        if (hasError) {
          return false;
        }

        await AdminManager.replyComment(
          comment.id,
          commentDetails
        );

        comment.children_count = 1;
        return true;
      };


      this.showModal(
        $modal,
        async function () {
          try {
            let response = await AdminManager.getClassChildrenComments(comment.id);
            let txt = '';
            if (response.comments.length > 0) {
              txt = response.comments[0].comment;
            }
            $modal.find("[data-comment]").val(txt);
            $modal.find("[data-content]").show();
            $ctx.$nextTick(() => {
              $modal.find("[data-comment]").focus();
            });
          } catch (err) {
            $modal
              .find("[data-loading-error]")
              .html("Erro ao carregar resposta. Por favor, tente novamente")
              .show();
          } finally {
            $modal.find("[data-loading]").hide();
            $modal.find(".btn-close").removeClass("disabled");
          }
        },
        "Salvar",
        action,
        "Resposta atualizada.",
        "Ocorreu um erro editar a resposta.\nPor favor, tente novamente."
      );
    },

    async fetchMore() {
      let ctx = this;
      ctx.loadingMore = true;
      let lastComment = null;
      if (this.comments && this.comments.length > 0) {
        lastComment = this.comments[this.comments.length - 1];
      }

      try {
        let response = await AdminManager.getClassComments(
          ctx.course.id,
          ctx.classObj.id,
          lastComment,
          RESULT_COUNT
        );

        this.comments = this.comments.concat(response.comments);
        if (response.comments.length < RESULT_COUNT) {
          this.hasMore = false;
        }

        ctx.$nextTick(() => {
          if (response.comments.length > 0) {
            let card = $(".comments")
              .find("#" + response.comments[0].id)
              .get(0);
            let scrollDiv = card.offsetTop - 10;
            window.scrollTo({ top: scrollDiv, behavior: "smooth" });
          }
        });
      } catch (err) {
        console.log(err);
      } finally {
        ctx.loadingMore = false;
      }
    },
  },
};
</script>
