<template>
  <div class="loading_container" style="flex-direction: column">
    <h2 class="text-danger" v-if="$route.query.error">Ocorreu um erro na autenticação. Por favor, tente novamente.</h2>
    <h2 class="text-dark px-3" v-else>
      Você precisa estar logado para acessar esta página.
    </h2>
    <div class="text-center mt-3">
      <a class="hollow btn btn-primary" :href="loginURL">
        <img style="margin-bottom: 3px; margin-right: 5px; width: 20px" alt="Google login"
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png" />
        Sign in with Google
      </a>
    </div>

  </div>
</template>

<script>
import Config from "../../../../resources/config.js"
export default {
  created() {
    this.$store.commit("setActiveSection", "LoginPage");
    this.$store.commit("setLoading", false);
  },

  components: {},

  data() {
    return {};
  },
  computed: {
    loginURL() {
      let url =  Config.apiHost + "/auth/google/admin"; 
      if (this.$route.query.next) {
      url += '?next=' + this.$route.query.next;
      }
      return url;
    }
  },

  methods: {



  },
};
</script>
