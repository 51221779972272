<template>
  <div class="loading_container" v-if="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
  <div class="main" v-else>
    <!-- Side Navbar -->
    <SideBar />
    <!-- Body-Content -->
    <div class="body-content">
      <!-- Top Navbar -->
      <TopBar />
      <!-- Body -->
      <div class="container-fluid body-container">
        <div class="alert alert-warning" v-if="project.client.status !== 2">
          O status deste projeto não pode ser alterado pois o cadastro do cliente não está ativo.
        </div>
        <div class="alert alert-warning text-center" v-else-if="project.status === 1">
          Este projeto está aguardando moderação.
          <div class="admin-buttons-h mt-3">
            <a class="btn bg-success" @click="approveProject">Aprovar projeto</a>
            <a class="btn btn-cancel" @click="rejectProject">Reprovar projeto</a>
          </div>
        </div>
        <div class="alert alert-success text-center" v-else-if="project.status === 2">
          Este projeto está ativo.
          <div class="admin-buttons-h mt-3">
            <a class="btn btn-cancel" @click="inactivateProject">Desativar projeto</a>
          </div>
        </div>
        <div class="alert alert-danger text-center" v-else-if="project.status === 3">
          Este projeto está desativado.
          <div class="admin-buttons-h mt-3">
            <a class="btn bg-success" @click="activateProject">Ativar projeto</a>
          </div>
        </div>
        <div class="alert alert-warning text-center" v-else-if="project.status === 4">
          Este projeto está fechado.
          <div class="admin-buttons-h mt-3">
            <a class="btn bg-danger" @click="inactivateProject">Desativar projeto</a>
          </div>
        </div>

        <div class="row project_area" style="margin-left: 0; margin-right: 0">
          <div class="col-lg-8">
            <div class="project_info">
              <div class="project_heading break-word">
                <h2 class="mb-0">{{ project.title }}</h2>
                <p class="date m-0 p-0 mb-2" style="font-size: 10pt">
                  ID: {{ project.id }}
                </p>
                <p class="date">
                  Última atualização:
                  {{ formatTimestamp(project.last_update_timestamp) }}
                </p>
              </div>
              <div class="project_heading">
                <div class="wrap-box">
                  <p class="break-word break-line">
                    <span>{{ project.description }}</span>
                  </p>
                  <label></label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="sm_sclient_img box">
              <a :href="'/cliente/' + project.client_id">
                <img :src="getImageURL(project.client.picture)" v-if="project.client.picture" />
                <img class="empty-image" v-else alt="" />
                <p>{{ project.client.name }}</p>
              </a>
            </div>
            <div class="status box">
              <h2>
                <a class="btn" style="cursor: pointer" href="javascript:void(0)" @click="editProject">Editar projeto</a>
              </h2>
            </div>
          </div>
        </div>

        <p class="mt-4 text-muted fw-bold">Propostas</p>
        <div v-if="!project.proposals || project.proposals.length === 0">
          <p>Nenhuma proposta enviada para este projeto.</p>
        </div>
        <div class="active-project" style="padding:0" v-else>
          <a class="card" style="position: static" v-for="proposal in project.proposals" :key="proposal.assistant_id"
            :href="'/proposta/' + proposal.assistant_id + '/' + project.id">
            <div class="name">
              <p>
                Assistente <br />
                <span>{{ proposal.name }}</span>
              </p>
            </div>
            <div class="date">
              <p>
                Data de registro <br />
                <span>{{
                  formatTimestamp(proposal.registration_timestamp)
                }}</span>
              </p>
            </div>
            <div class="count">
              <p>
                Aceita <br />
                <span>{{ proposal.released ? 'Sim' : 'Não' }}</span>
              </p>
            </div>
            <div class="status">
              <p>
                Status <br />
                <span :class="getTextStatusClass(proposal.status)">
                  {{ formatProjectStatus(proposal.status) }}</span>
              </p>
            </div>
          </a>
        </div>


      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import SideBar from "../SideBar.vue";
import TopBar from "../TopBar.vue";
import AdminManager from "../../../js/services/admin.js";
import StringUtils from "../../../js/utils/string.js";
import Config from "../../../../resources/config.js";

export default {
  components: { SideBar, TopBar },

  created() {
    this.$store.commit("setActiveSection", "ProjectPage");
    this.loadProject();
  },

  data() {
    return {
      project: null,
      errorCode: null,
    };
  },

  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },

  methods: {
    async loadProject() {
      this.$store.commit("setLoading", true);
      try {
        let response = await AdminManager.getProject(this.$route.params.projectId);
        this.profile = response.profile;
        this.project = response.project;
        this.$store.commit("setActiveUser", this.profile);
        this.$store.commit("setLoading", false);
        this.applyTruncation();
      } catch (error) {
        this.handleAPIError(error, "projeto/" + this.$route.params.projectId);
      }
    },

    projectWasClosed(project) {
      if (project.released_contacts > project.max_contacts) {
        return true;
      }
      if (project.expiration_timestamp !== null && project.expiration_timestamp <= Date.now()) {
        return true;
      }

      return false;
    },

    approveProject() {
      let $ctx = this;

      let action = async function () {
        let projectDetails = {
          status: 2,
        };

        let updatedProject = await AdminManager.updateProject(
          $ctx.project.id,
          projectDetails
        );

        $ctx.project = updatedProject;
        return true;
      };

      this.showConfirmation(
        "Aprovar projeto",
        "Tem certeza que deseja aprovar este projeto?",
        "Aprovar",
        action,
        "O projeto foi aprovado.",
        "Ocorreu um erro ao aprovar o projeto.\nPor favor, tente novamente."
      );
    },

    activateProject() {
      let $ctx = this;

      let action = async function () {
        let projectDetails = {
          status: 2,
        };

        let updatedProject = await AdminManager.updateProject(
          $ctx.project.id,
          projectDetails
        );

        $ctx.project = updatedProject;
        return true;
      };

      this.showConfirmation(
        "Ativar projeto",
        "Tem certeza que deseja ativar este projeto?",
        "Ativar",
        action,
        "O projeto foi ativado.",
        "Ocorreu um erro ativar o projeto.\nPor favor, tente novamente."
      );
    },

    rejectProject() {
      let $ctx = this;

      let action = async function () {
        let projectDetails = {
          status: 3,
        };

        let updatedProject = await AdminManager.updateProject(
          $ctx.project.id,
          projectDetails
        );

        $ctx.project = updatedProject;
        return true;
      };

      this.showConfirmation(
        "Reprovar projeto",
        "Tem certeza que deseja reprovar este projeto?",
        "Reprovar",
        action,
        "O projeto foi reprovado.",
        "Ocorreu um erro reprovar o projeto.\nPor favor, tente novamente."
      );
    },

    inactivateProject() {
      let $ctx = this;

      let action = async function () {
        let projectDetails = {
          status: 3,
        };

        let updatedProject = await AdminManager.updateProject(
          $ctx.project.id,
          projectDetails
        );

        $ctx.project = updatedProject;
        return true;
      };

      this.showConfirmation(
        "Desativar projeto",
        "Tem certeza que deseja desativar este projeto?",
        "Desativar",
        action,
        "O projeto foi desativado.",
        "Ocorreu um erro desativar o projeto.\nPor favor, tente novamente."
      );
    },

    editProject() {
      let $ctx = this;
      let $modal = $("#editProjectModal");
      $modal.find(".item").removeClass("error");

      let action = async function () {
        let projectDetails = {
          title: $modal.find("[data-project-title]").val(),
          description: $modal.find("[data-project-description]").val(),
        };

        let hasError = false;
        $modal.find(".item").removeClass("error");

        if (projectDetails.title.length < 10) {
          $modal
            .find("[data-project-title]")
            .closest(".item")
            .addClass("error");
          hasError = true;
        }

        if (projectDetails.description.length < 50) {
          $modal
            .find("[data-project-description]")
            .closest(".item")
            .addClass("error");
          hasError = true;
        }

        if (hasError) {
          return false;
        }

        let updatedProject = await AdminManager.updateProject(
          $ctx.project.id,
          projectDetails
        );

        $ctx.project = updatedProject;
        return true;
      };

      $modal.find("[data-project-title]").val($ctx.project.title);
      $modal.find("[data-project-description]").val($ctx.project.description);

      this.showModal(
        $modal,
        function () {
          $modal.find("[data-project-title]").focus();
        },
        "Salvar",
        action,
        "Projeto atualizado.",
        "Ocorreu um erro editar o projeto.\nPor favor, tente novamente."
      );
    },
  },
};
</script>
