<template>
  <div class="loading_container" v-if="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
  <div class="main" v-else>
    <!-- Side Navbar -->
    <SideBar />
    <!-- Body-Content -->
    <div class="body-content">
      <!-- Top Navbar -->
      <TopBar />
      <!-- Body -->
      <div class="container-fluid body-container">
        <div class="alert alert-danger" v-if="video.status === 3">
          Este vídeo está inativo.
        </div>
        <section class="video_play_page">
          <h1 class="video-title">
            {{ video.title }}
            <button class="btn btn-small" @click="editVideo()">Editar</button>
          </h1>
          <div class="row">
            <div class="col-lg-12">
              <div class="video-container" v-if="video.type === 1">
                <video-js
                  id="player"
                  controls
                  controlsList="nodownload"
                  fluid="true"
                  class="video-js vjs-theme-city vjs-big-play-centered"
                >
                  <source
                    :src="getVideoURL(video)"
                    type="application/x-mpegURL"
                    data-setup='{ "playbackRates": [0.5, 1, 1.5, 2] }'
                  />
                </video-js>
              </div>
              <div
                class="iframe-embed-wrapper iframe-embed-responsive-16by9"
                v-else-if="video.type === 2"
              >
                <iframe
                  class="iframe-embed"
                  width="100%"
                  height="auto"
                  title="YouTube video player"
                  frameborder="0"
                  loading="lazy"
                  :src="video.path"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="video-details">
                <div class="wrap-box short mt-3">
                  <p class="break-word" style="font-size: 14.2px">
                    {{ video.description }}
                  </p>
                  <label></label>
                </div>
              </div>
            </div>
            <div class="col-lg-12 mt-4" v-if="video.type === 1">
              <hr style="margin-bottom: 5px" v-if="comments.length > 0" />
              <div class="alert alert-warning mt-3" v-if="comments.length === 0">
                Nenhum comentário sobre esse vídeo ainda.
              </div>
              <div class="comments" v-else>
                <div
                  class="box"
                  v-for="comment in comments"
                  :key="comment.id"
                  :id="comment.id"
                >
                  <img :src="getImageURL(comment.picture)" v-if="comment.picture" />
                  <img class="empty-image" v-else />
                  <div class="c_text_area">
                    <p class="name">
                      {{ comment.name ? comment.name : "Virtap"
                      }}<span>{{
                        formatElapsedTime(comment.registration_timestamp)
                      }}</span>
                    </p>
                    <div class="wrap-box short">
                      <p
                        class="break-word dsc"
                        v-html="formatMultilineText(comment.comment)"
                      ></p>
                      <label></label>
                    </div>
                    <div v-if="comment.status === 1">
                      <p class="under-review">
                        Este comentário está aguardando moderação e encontra-se invisível
                        no momento.
                      </p>
                      <div class="mt-2">
                        <button
                          class="btn btn-small bg-success"
                          style="margin-right: 5px"
                          @click="approveComment(comment)"
                        >
                          Aprovar
                        </button>
                        <button
                          class="btn btn-small bg-danger"
                          style="margin-right: 5px"
                          @click="reproveComment(comment)"
                        >
                          Reprovar
                        </button>
                        <button class="btn btn-small" @click="editComment(comment)">
                          Editar
                        </button>
                      </div>
                    </div>
                    <div v-if="comment.status === 2">
                      <div class="mt-2">
                        <button
                          class="btn btn-small bg-danger"
                          style="margin-right: 5px"
                          @click="deactivateComment(comment)"
                        >
                          Desativar
                        </button>
                        <button class="btn btn-small" @click="editComment(comment)">
                          Editar
                        </button>
                      </div>
                    </div>
                    <div v-else-if="comment.status === 3">
                      <p class="inactive">Este comentário foi desativado.</p>
                      <div class="mt-2">
                        <button
                          class="btn btn-small bg-success"
                          style="margin-right: 5px"
                          @click="activateComment(comment)"
                        >
                          Ativar
                        </button>
                        <button class="btn btn-small" @click="editComment(comment)">
                          Editar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="page_number" v-if="video.type === 1">
            <button
              id="fetchMoreComments"
              class="btn see_more btn-small"
              :class="{ disabled: loadingMore }"
              @click="fetchMore()"
              v-if="hasMore"
            >
              {{ loadingMore ? "Carregando..." : "Mostrar mais" }}
            </button>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "../SideBar.vue";
import TopBar from "../TopBar.vue";
import AdminManager from "../../../js/services/admin.js";
import StringUtils from "../../../js/utils/string.js";
import Config from "../../../../resources/config.js";

const RESULT_COUNT = 1000;

export default {
  components: { SideBar, TopBar },

  created() {
    this.$store.commit("setActiveSection", "VideoPage");
    this.loadVideo();
  },

  data() {
    return {
      video: null,
      posting: false,
      comments: [],
      loadingMore: false,
      hasMore: true,
    };
  },

  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },

  methods: {
    async loadVideo() {
      this.$store.commit("setLoading", true);
      try {
        let response = await AdminManager.getVideo(
          this.$route.params.videoId,
          RESULT_COUNT
        );
        this.video = response.video;
        this.comments = response.comments;
        this.hasMore = this.comments.length >= RESULT_COUNT;
        this.$store.commit("setActiveUser", response.profile);
        this.$store.commit("setLoading", false);
        this.applyTruncation();
        this.$nextTick(() => {
          let player = videojs("player", {
            playbackRates: [0.5, 1, 1.5, 2],
            html5: {
              hls: {
                withCredentials: true,
              },
            },
          });
          player.play();
        });
      } catch (error) {
        this.handleAPIError(error, "video/" + this.$route.params.videoId);
      }
    },

    async approveComment(comment) {
      let action = async function () {
        let commentDetails = {
          status: 2,
        };

        let updatedComment = await AdminManager.updateComment(comment.id, commentDetails);

        comment.status = updatedComment.status;
        return true;
      };

      this.showConfirmation(
        "Aprovar comentário",
        "Tem certeza que deseja aprovar este comentário?",
        "Aprovar",
        action,
        "Comentário aprovado",
        "Ocorreu um erro ao aprovar o comentário.\nPor favor, tente novamente."
      );
    },

    async activateComment(comment) {
      let action = async function () {
        let commentDetails = {
          status: 2,
        };

        let updatedComment = await AdminManager.updateComment(comment.id, commentDetails);

        comment.status = updatedComment.status;
        return true;
      };

      this.showConfirmation(
        "Ativar comentário",
        "Tem certeza que deseja ativar este comentário?",
        "Ativar",
        action,
        "Comentário ativado",
        "Ocorreu um erro ao ativar o comentário.\nPor favor, tente novamente."
      );
    },

    async reproveComment(comment) {
      let action = async function () {
        let commentDetails = {
          status: 3,
        };

        let updatedComment = await AdminManager.updateComment(comment.id, commentDetails);

        comment.status = updatedComment.status;
        return true;
      };

      this.showConfirmation(
        "Reprovar comentário",
        "Tem certeza que deseja reprovar este comentário?",
        "Reprovar",
        action,
        "Comentário reprovado",
        "Ocorreu um erro ao reprovar o comentário.\nPor favor, tente novamente."
      );
    },

    async deactivateComment(comment) {
      let action = async function () {
        let commentDetails = {
          status: 3,
        };

        let updatedComment = await AdminManager.updateComment(comment.id, commentDetails);

        comment.status = updatedComment.status;
        return true;
      };

      this.showConfirmation(
        "Desativar comentário",
        "Tem certeza que deseja desativar este comentário?",
        "Desativar",
        action,
        "Comentário desativado",
        "Ocorreu um erro ao desativar o comentário.\nPor favor, tente novamente."
      );
    },

    async editVideo() {
      let $ctx = this;
      let $modal = $("#editVideoModal");
      $modal.find(".item").removeClass("error");

      let action = async function () {
        let videoDetails = {
          type: $modal.find("[data-video-type]").val(),
          title: $modal.find("[data-video-title]").val(),
          description: $modal.find("[data-video-description]").val(),
          path: $modal.find("[data-video-path]").val(),
          status: $modal.find("[data-video-status]").val(),
        };

        let hasError = false;
        $modal.find(".item").removeClass("error");

        if (videoDetails.title.length < 10) {
          $modal.find("[data-video-title]").closest(".item").addClass("error");
          hasError = true;
        }
        if (videoDetails.path.length < 10) {
          $modal.find("[data-video-path]").closest(".item").addClass("error");
          hasError = true;
        }

        if (videoDetails.description.length < 50) {
          $modal.find("[data-video-description]").closest(".item").addClass("error");
          hasError = true;
        }

        if (hasError) {
          return false;
        }

        let updatedVideo = await AdminManager.updateVideo($ctx.video.id, videoDetails);

        $ctx.video = updatedVideo;
        return true;
      };

      $modal.find("[data-video-type]").val($ctx.video.type);
      $modal.find("[data-video-title]").val($ctx.video.title);
      $modal.find("[data-video-description]").val($ctx.video.description);
      $modal.find("[data-video-path]").val($ctx.video.path);
      $modal.find("[data-video-status]").val($ctx.video.status);

      this.showModal(
        $modal,
        function () {
          $modal.find("[data-video-title]").focus();
        },
        "Salvar",
        action,
        "Video atualizado.",
        "Ocorreu um erro editar o video.\nPor favor, tente novamente."
      );
    },

    async editComment(comment) {
      let $ctx = this;
      let $modal = $("#editCommentModal");
      $modal.find(".item").removeClass("error");

      let action = async function () {
        let commentDetails = {
          comment: $modal.find("[data-comment]").val(),
        };

        let hasError = false;
        $modal.find(".item").removeClass("error");

        if (commentDetails.comment.length < 10) {
          $modal.find("[data-comment]").closest(".item").addClass("error");
          hasError = true;
        }

        if (hasError) {
          return false;
        }

        let updatedComment = await AdminManager.updateComment(comment.id, commentDetails);

        comment.comment = updatedComment.comment;
        return true;
      };

      $modal.find("[data-comment]").val(comment.comment);

      this.showModal(
        $modal,
        function () {
          $modal.find("[data-comment]").focus();
        },
        "Salvar",
        action,
        "Comentário atualizado.",
        "Ocorreu um erro editar o comentário.\nPor favor, tente novamente."
      );
    },

    async fetchMore() {
      let ctx = this;
      ctx.loadingMore = true;
      let lastComment = null;
      if (this.comments && this.comments.length > 0) {
        lastComment = this.comments[this.comments.length - 1];
      }

      try {
        let response = await AdminManager.getComments(
          ctx.video.id,
          lastComment,
          RESULT_COUNT
        );

        this.comments = this.comments.concat(response.comments);
        if (response.comments.length < RESULT_COUNT) {
          this.hasMore = false;
        }

        ctx.$nextTick(() => {
          if (response.comments.length > 0) {
            let card = $(".comments")
              .find("#" + response.comments[0].id)
              .get(0);
            let scrollDiv = card.offsetTop - 10;
            window.scrollTo({ top: scrollDiv, behavior: "smooth" });
          }
        });
      } catch (err) {
        console.log(err);
      } finally {
        ctx.loadingMore = false;
      }
    },
  },
};
</script>
