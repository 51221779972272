<template>
  <div class="loading_container" v-if="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
  <div class="main" v-else>
    <!-- Side Navbar -->
    <SideBar />
    <!-- Body-Content -->
    <div class="body-content">
      <!-- Top Navbar -->
      <TopBar />
      <!-- Body -->
      <div class="container-fluid body-container">
        <div class="element">
          <div class="search-box-container">
            <div class="nav" style="border-radius: 8px 8px 0 0">
              <h1>
                Cursos
                <span v-if="!listLoading && !listError">({{ courseCount }})</span>
              </h1>
              <select class="form-select" @change="selectNewStatus($event)" :disabled="listLoading">
                <option value="">Todos</option>
                <option value="2">Ativos</option>
                <option value="3">Desativados</option>
              </select>
            </div>
          </div>
          <div class="project-list">
            <div class="post_project post_project-active" style="padding: 20px; height: auto"
              :class="{ nopro: courses && courses.length === 0 }">
              <div class="loading_container" v-if="listLoading" style="height: 200px">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only"></span>
                </div>
              </div>
              <div v-else-if="listError" class="create-project">
                <div class="card">
                  <div class="card-body">
                    <h2 class="text-danger">Erro</h2>
                    <p class="text-danger">
                      Ocorreu um erro ao atualizar a lista de cursos.<br />
                      Por favor, tente novamente.
                    </p>
                  </div>
                </div>
              </div>
              <div class="create-project" v-else-if="!courses || courses.length === 0">
                <div class="card">
                  <img src="assets/img/project.png" alt="" />
                  <div class="card-body">
                    <h2>Nenhum curso encontrado</h2>
                  </div>
                </div>
              </div>
              <div class="active-project" style="padding:0" v-else>
                <a class="card" style="position: static" v-for="course in courses" :key="course.id"
                  :href="courseURL(course)">
                  <div class="name">
                    <p>
                      Título <br />
                      <span>{{ course.title }}</span>
                    </p>
                  </div>
                  <div class="date">
                    <p>
                      Data de registro <br />
                      <span>{{
                        formatTimestamp(course.registration_timestamp)
                      }}</span>
                    </p>
                  </div>
                  <div class="count">
                    <p>
                      ID <br />
                      <span>{{ course.id }}</span>
                    </p>
                  </div>
                  <div class="status">
                    <p>
                      Status <br />
                      <span :class="getTextStatusClass(course.status)">
                        {{ formatProjectStatus(course.status) }}</span>
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="text-center">
          <button class="btn mt-3" @click="addCourse()">Adicionar vídeo</button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import SideBar from "../SideBar.vue";
import TopBar from "../TopBar.vue";
import AdminManager from "../../../js/services/admin.js";
import StringUtils from "../../../js/utils/string.js";
import Config from "../../../../resources/config.js";

export default {
  components: { SideBar, TopBar },

  created() {
    this.$store.commit("setActiveSection", "CourseListPage");
    this.loadClients();
  },

  data() {
    return {
      courses: null,
      listLoading: false,
      listError: null,
    };
  },

  computed: {
    loading() {
      return this.$store.state.loading;
    },

    courseCount() {
      if (this.courses) {
        return this.courses.length;
      }
      return 0;
    },
  },

  methods: {
    async loadClients() {
      this.$store.commit("setLoading", true);
      try {
        let response = await AdminManager.getCourses();
        let profile = response.profile;
        this.courses = response.courses;
        this.$store.commit("setActiveUser", profile);
        this.$store.commit("setLoading", false);
      } catch (error) {
        this.handleAPIError(error, "cursos");
      }
    },

    async selectNewStatus(event) {
      let selectedStatus = event.target.value;
      this.listLoading = true;
      this.listError = null;
      try {
        let response = await AdminManager.getCourses(selectedStatus);
        this.courses = response.courses;
      } catch (err) {
        console.log(err);
        this.listError = err;
      } finally {
        this.listLoading = false;
      }
    },

    courseURL(course) {
      return Config.apiHost + '/admin/go-to-course/' + course.id;
    },

    async addCourse() {
      let $ctx = this;
      let $modal = $("#editCourseModal");
      $modal.find(".item").removeClass("error");

      let action = async function () {
        let courseDetails = {
          type: $modal.find("[data-course-type]").val(),
          title: $modal.find("[data-course-title]").val(),
          description: $modal.find("[data-course-description]").val(),
          path: $modal.find("[data-course-path]").val(),
          status: $modal.find("[data-course-status]").val()
        };

        let hasError = false;
        $modal.find(".item").removeClass("error");

        if (courseDetails.title.length < 10) {
          $modal
            .find("[data-course-title]")
            .closest(".item")
            .addClass("error");
          hasError = true;
        }
        if (courseDetails.path.length < 10) {
          $modal
            .find("[data-course-path]")
            .closest(".item")
            .addClass("error");
          hasError = true;
        }

        if (courseDetails.description.length < 50) {
          $modal
            .find("[data-course-description]")
            .closest(".item")
            .addClass("error");
          hasError = true;
        }


        if (hasError) {
          return false;
        }

        let updatedCourse = await AdminManager.addCourse(
          courseDetails
        );
        updatedCourse.total_comments = 0;

        $ctx.courses.unshift(updatedCourse);
        return true;
      };


      $modal.find("[data-course-type]").val('1');
            $modal.find("[data-course-title]").val('');
            $modal.find("[data-course-description]").val('');
            $modal.find("[data-course-path]").val('');
            $modal.find("[data-course-status]").val('2');

      this.showModal(
        $modal,
        function () {
          $modal.find("[data-course-title]").focus();
        },
        "Salvar",
        action,
        "Course adicionado.",
        "Ocorreu um erro adicionar o curso.\nPor favor, tente novamente."
      );
    }
  },
};
</script>
