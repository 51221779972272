import Config from "../../../resources/config.js"

async function handleResponse(response) {
  if (response.status === 200) {
    let responseObject = await response.json();
    return responseObject.responseData;
  } else if (response.status === 400) {
    throw await response.json();
  } else {
    throw response;
  }
}

export default {

  async getAdminData() {
    let url = `${Config.apiHost}/admin/dashboard`;
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async getAssistants(status) {
    let url = `${Config.apiHost}/admin/get-assistants?n=1000`;
    if (status) {
      url += '&status=' + status
    }
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async getClients(status) {
    let url = `${Config.apiHost}/admin/get-clients?n=1000`;
    if (status) {
      url += '&status=' + status
    }
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async getProjects(status) {
    let url = `${Config.apiHost}/admin/get-projects?n=1000`;
    if (status) {
      url += '&status=' + status
    }
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async getProposals(status) {
    let url = `${Config.apiHost}/admin/get-proposals?n=1000`;
    if (status) {
      url += '&status=' + status
    }
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async getVideos(status) {
    let url = `${Config.apiHost}/admin/get-videos?n=1000`;
    if (status) {
      url += '&status=' + status
    }
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async getCourses(status) {
    let url = `${Config.apiHost}/admin/get-courses?n=1000`;
    if (status) {
      url += '&status=' + status
    }
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },


  async getAssistant(assistantId) {
    let url = `${Config.apiHost}/admin/get-assistant/${assistantId}`;
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async getClient(clientId) {
    let url = `${Config.apiHost}/admin/get-client/${clientId}`;
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async getProject(projectId) {
    let url = `${Config.apiHost}/admin/get-project/${projectId}`;
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async getProposal(assistantId, projectId) {
    let url = `${Config.apiHost}/admin/get-proposal/${assistantId}/${projectId}`;
    if (status) {
      url += '&status=' + status
    }
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async getVideo(videoId, n) {
    let url = `${Config.apiHost}/admin/get-video/${videoId}?n=${n}`;
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async getClass(courseId, classId, n) {
    let url = `${Config.apiHost}/admin/get-course-class/${courseId}/${classId}?n=${n}`;
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },
 
  async getClassComments(courseId, classId, lastComment, resultCount) {
    let url = `${Config.apiHost}/admin/get-class-comments/${courseId}/${classId}?n=${resultCount}`;

    if (lastComment) {
      url += '&lv=' + lastComment.registration_timestamp + "&lp=" + lastComment.position;
    }

    let response = await fetch(url, {
      method: "GET",
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async getClassChildrenComments(commentId) {
    let url = `${Config.apiHost}/admin/get-class-children-comments/${commentId}`;

    let response = await fetch(url, {
      method: "GET",
      credentials: "include"
    });

    return await handleResponse(response);
  },


  async replyComment(commentId, commentDetails) {
    let url = `${Config.apiHost}/admin/reply-class-comment/${commentId}`;
    let formData = new FormData();

    if (commentDetails.comment) {
      formData.append('comment', commentDetails.comment);
    }

    let response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include"
    });

    return await handleResponse(response);
  },
 

  async getComments(videoId, lastComment, resultCount) {
    let url = `${Config.apiHost}/admin/get-comments/${videoId}?n=${resultCount}`;

    if (lastComment) {
      url += '&lv=' + lastComment.registration_timestamp + "&lp=" + lastComment.position;
    }

    let response = await fetch(url, {
      method: "GET",
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async updateComment(commentId, commentDetails) {
    let url = `${Config.apiHost}/admin/update-comment/${commentId}`;
    let formData = new FormData();

    if (commentDetails.comment) {
      formData.append('comment', commentDetails.comment);
    }

    if (commentDetails.status) {
      formData.append('status', commentDetails.status);
    }

    let response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include"
    });

    return await handleResponse(response);
  },
 
  async updateClassComment(commentId, commentDetails) {
    let url = `${Config.apiHost}/admin/update-class-comment/${commentId}`;
    let formData = new FormData();

    if (commentDetails.comment) {
      formData.append('comment', commentDetails.comment);
    }

    if (commentDetails.status) {
      formData.append('status', commentDetails.status);
    }

    let response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async updateAssistant(assistantId, assistantDetails) {
    let url = `${Config.apiHost}/admin/update-assistant/${assistantId}`;
    let formData = new FormData();
    let pictureFile = assistantDetails.picture;
    let removePicture = assistantDetails.removePicture;

    delete assistantDetails['picture'];
    delete assistantDetails['removePicture'];

    if (assistantDetails.name) {
      formData.append('name', assistantDetails.name);
    }

    if (assistantDetails.title) {
      formData.append('title', assistantDetails.title);
    }

    if (assistantDetails.plan) {
      formData.append('plan', assistantDetails.plan);
    }

    if (assistantDetails.description) {
      formData.append('description', assistantDetails.description);
    }

    if (assistantDetails.expertise_areas) {
      formData.append('expertise_areas', JSON.stringify(assistantDetails.expertise_areas));
    }

    if (assistantDetails.certifications) {
      formData.append('certifications', JSON.stringify(assistantDetails.certifications));
    }

    if (assistantDetails.status) {
      formData.append('status', assistantDetails.status);
    }

    if (pictureFile) {
      formData.append('picture_file', pictureFile);
    } else if (removePicture) {
      formData.append('remove_picture', true);
    }

    let response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async syncSubscription(assistantId) {
    let url = `${Config.apiHost}/admin/sync-subscription/${assistantId}`;
    let formData = new FormData();

    let response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async updateClient(clientId, clientDetails) {
    let url = `${Config.apiHost}/admin/update-client/${clientId}`;
    let formData = new FormData();
    let pictureFile = clientDetails.picture;
    let removePicture = clientDetails.removePicture;

    delete clientDetails['picture'];
    delete clientDetails['removePicture'];

    if (clientDetails.name) {
      formData.append('name', clientDetails.name);
    }

    if (clientDetails.contact_info) {
      formData.append('contact_info', JSON.stringify(clientDetails.contact_info));
    }

    if (clientDetails.status) {
      formData.append('status', clientDetails.status);
    }

    if (pictureFile) {
      formData.append('picture_file', pictureFile);
    } else if (removePicture) {
      formData.append('remove_picture', true);
    }

    let response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async updateProject(projectId, projectDetails) {
    let url = `${Config.apiHost}/admin/update-project/${projectId}`;
    let formData = new FormData();

    if (projectDetails.title) {
      formData.append('title', projectDetails.title);
    }

    if (projectDetails.description) {
      formData.append('description', projectDetails.description);
    }

    if (projectDetails.status) {
      formData.append('status', projectDetails.status);
    }

    let response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async updateProposal(assistantId, projectId, proposalDetails) {
    let url = `${Config.apiHost}/admin/update-proposal/${assistantId}/${projectId}`;
    let formData = new FormData();

    if (proposalDetails.proposal) {
      formData.append('proposal', proposalDetails.proposal);
    }

    if (proposalDetails.status) {
      formData.append('status', proposalDetails.status);
    }

    let response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async addVideo(videoDetails) {
    let url = `${Config.apiHost}/admin/add-video`;
    let formData = new FormData();

    if (videoDetails.type) {
      formData.append('type', videoDetails.type);
    }

    if (videoDetails.title) {
      formData.append('title', videoDetails.title);
    }

    if (videoDetails.path) {
      formData.append('path', videoDetails.path);
    }

    if (videoDetails.description) {
      formData.append('description', videoDetails.description);
    }

    if (videoDetails.status) {
      formData.append('status', videoDetails.status);
    }

    let response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async updateVideo(videoId, videoDetails) {
    let url = `${Config.apiHost}/admin/update-video/${videoId}`;
    let formData = new FormData();

    if (videoDetails.type) {
      formData.append('type', videoDetails.type);
    }

    if (videoDetails.title) {
      formData.append('title', videoDetails.title);
    }

    if (videoDetails.path) {
      formData.append('path', videoDetails.path);
    }

    if (videoDetails.description) {
      formData.append('description', videoDetails.description);
    }

    if (videoDetails.status) {
      formData.append('status', videoDetails.status);
    }

    let response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async updateCourse(courseId, courseDetails) {
    let url = `${Config.apiHost}/admin/update-course/${courseId}`;
    let formData = new FormData();

    if (courseDetails.title) {
      formData.append('title', courseDetails.title);
    }

    if (courseDetails.path) {
      formData.append('path', courseDetails.path);
    }

    if (courseDetails.status) {
      formData.append('status', courseDetails.status);
    }

    let response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async updateModule(moduleId, moduleDetails) {
    let url = `${Config.apiHost}/admin/update-module/${moduleId}`;
    let formData = new FormData();

    if (moduleDetails.title) {
      formData.append('title', moduleDetails.title);
    }

    if (moduleDetails.path) {
      formData.append('path', moduleDetails.path);
    }

    if (moduleDetails.number) {
      formData.append('number', moduleDetails.number);
    }

    let response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async updateClass(courseId, classId, classDetails) {
    let url = `${Config.apiHost}/admin/update-class/${courseId}/${classId}`;
    let formData = new FormData();

    if (classDetails.title) {
      formData.append('title', classDetails.title);
    }

    if (classDetails.path) {
      formData.append('path', classDetails.path);
    }
 
    if (classDetails.number) {
      formData.append('number', classDetails.number);
    }

    let response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async addReview(assistantId, projectId, reviewDetails) {
    let url = `${Config.apiHost}/admin/add-review/${projectId}/${assistantId}`;
    let formData = new FormData();

    formData.append('assistant_rating', reviewDetails.assistant_rating);
    formData.append('assistant_comment', reviewDetails.assistant_comment);

    formData.append('client_rating', reviewDetails.client_rating);
    formData.append('client_comment', reviewDetails.client_comment);

    let response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async updateReview(assistantId, projectId, reviewDetails) {
    let url = `${Config.apiHost}/admin/update-review/${projectId}/${assistantId}`;
    let formData = new FormData();

    formData.append('assistant_rating', reviewDetails.assistant_rating);
    formData.append('assistant_comment', reviewDetails.assistant_comment);

    formData.append('client_rating', reviewDetails.client_rating);
    formData.append('client_comment', reviewDetails.client_comment);

    let response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async activateReview(assistantId, projectId) {
    let url = `${Config.apiHost}/admin/activate-review/${projectId}/${assistantId}`;
    let formData = new FormData();
 
    let response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async inactivateReview(assistantId, projectId) {
    let url = `${Config.apiHost}/admin/inactivate-review/${projectId}/${assistantId}`;
    let formData = new FormData();

    let response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include"
    });

    return await handleResponse(response);
  },

}