<template>
  <div class="loading_container" v-if="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>

  <div class="main" v-else>
    <!-- Side Navbar -->
    <SideBar />
    <!-- Body-Content -->
    <div class="body-content">
      <!-- Top Navbar -->
      <TopBar />
      <!-- Body -->
      <div class="container-fluid body-container">
        <div class="alert alert-warning text-center" v-if="client.status === 1">
          Este perfil está aguardando moderação.
          <div class="admin-buttons-h mt-3">
            <a class="btn bg-success" @click="approveClient">Aprovar perfil</a>
            <a class="btn btn-cancel" @click="rejectClient">Reprovar perfil</a>
          </div>
        </div>
        <div class="alert alert-success text-center" v-else-if="client.status === 2">
          Este perfil está ativo.
          <div class="admin-buttons-h mt-3">
            <a class="btn btn-cancel" @click="inactivateClient">Desativar perfil</a>
          </div>
        </div>
        <div class="alert alert-danger text-center" v-else-if="client.status === 3">
          Este perfil está desativado.
          <div class="admin-buttons-h mt-3">
            <a class="btn bg-success" @click="activateClient">Ativar perfil</a>
          </div>
        </div>

        <div class="client-page">
          <div style="margin-right: 0">
            <div class="">
              <div class="talent_profile client_profile">
                <div class="talent-info">
                  <div class="freelancer_info">
                    <div class="freelancer">
                      <img class="freelancer_img" :src="getImageURL(client.picture)" v-if="client.picture" alt="" />
                      <img class="freelancer_img empty-image" v-else alt="" />
                      <div class="info">
                        <div>
                          <h2>
                            {{ client.name }}
                            <!-- <img src="assets/img/Icons.svg" alt="" /> -->
                          </h2>
                        </div>

                        <div class="down-icons-list">
                          <div class="star">                                
                          <i :class="star.class" class="icons" v-for="star in getRatingStars(client.feedback_rating)" :key="star.id"></i>
                          <span class="rating"> ({{ client.feedback_rating.toFixed(2) }})</span>
                        </div>

                        </div>
                      </div>
                    </div>
                    <div class="admin-buttons-v">
                      <a class="btn" @click="editClient">Editar perfil</a>
                    </div>
                  </div>
                </div>
                <div class="p-info">
                  <div class="act-project">
                    <p>
                      Total de projetos:
                      <span>{{ client.total_projects }}</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      Whatsapp:
                      <span class="break-all">{{
                        client.contact_info.whatsapp
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p class="mt-4 text-muted fw-bold">Projetos</p>
        <div v-if="!client.projects || client.projects.length === 0">
          <p>Nenhum projeto criado por este cliente.</p>
        </div>
        <div class="active-project" style="padding:0" v-else>
          <a class="card" style="position: static" v-for="project in client.projects" :key="project.id"
            :href="'/projeto/' + project.id">
            <div class="name">
              <p>
                Título <br />
                <span>{{ project.title }}</span>
              </p>
            </div>
            <div class="date">
              <p>
                Última atualização <br />
                <span>{{
                  formatTimestamp(project.last_update_timestamp)
                }}</span>
              </p>
            </div>
            <div class="count">
              <p>
                Propostas<br />
                <span>{{ project.total_proposals }}</span>
              </p>
            </div>
            <div class="status">
              <p>
                Status <br />
                <span :class="getTextStatusClass(project.status)">
                  {{ formatProjectStatus(project.status) }}</span>
              </p>
            </div>

          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import SideBar from "../SideBar.vue";
import TopBar from "../TopBar.vue";
import AdminManager from "../../../js/services/admin.js";
import StringUtils from "../../../js/utils/string.js";
import Config from "../../../../resources/config.js";

export default {
  components: { SideBar, TopBar },

  created() {
    this.$store.commit("setActiveSection", "ClientPage");
    this.loadClient();
  },

  data() {
    return {
      client: null,
      errorCode: null,
    };
  },

  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },

  methods: {
    async loadClient() {
      this.$store.commit("setLoading", true);
      try {
        let response = await AdminManager.getClient(
          this.$route.params.clientId
        );
        let profile = response.profile;
        let client = response.client;
        client.contact_info = JSON.parse(client.contact_info);

        this.client = client;
        this.$store.commit("setActiveUser", profile);
        this.$store.commit("setLoading", false);
      } catch (error) {
        this.handleAPIError(error, "cliente/" + this.$route.params.clientId);
      }
    },

    editClient() {
      let $ctx = this;
      let $modal = $("#editClientModal");
      let $profileImage = $("#editClientProfileImg");
      let $btnRemovePhoto = $modal.find("[data-remove-photo]");
      let $name = $modal.find("[data-client-name]");
      let $whatsapp = $modal.find("[data-client-whatsapp]");

      $modal.find("#finput_client").val("");
      $modal.find(".item").removeClass("error");
      $name.val($ctx.client.name);
      $whatsapp.val($ctx.client.contact_info.whatsapp);
      $modal.find("[data-client-email]").val($ctx.client.email_address);

      
      if (this.client.picture) {
        $profileImage.show();
        $profileImage.attr("src", this.getImageURL(this.client.picture));
        $btnRemovePhoto.show();
      } else {
        $btnRemovePhoto.hide();
        $profileImage.removeAttr("src");
      }
      $profileImage.data("file", null);

      $btnRemovePhoto.off().on("click", function () {
        $btnRemovePhoto.hide();
        $profileImage.removeAttr("src");
        $profileImage.data("file", null);
        $profileImage.data("removeFile", true);
      });

      let action = async function () {
        let clientDetails = {
          name: $name.val(),
          contact_info: {
            whatsapp: $whatsapp.val(),
          },
        };

        if ($profileImage.data("file")) {
          clientDetails.picture = $profileImage.data("file");
        } else if ($profileImage.data("removeFile")) {
          clientDetails.removePicture = true;
        }

        let hasError = false;
        $modal.find(".item").removeClass("error");

        if (clientDetails.name.length < 10) {
          $name.closest(".item").addClass("error");
          hasError = true;
        }

        if (clientDetails.contact_info.whatsapp.length < 10) {
          $whatsapp.closest(".item").addClass("error");
          hasError = true;
        }

        if (hasError) {
          return false;
        }

        let updatedClient = await AdminManager.updateClient(
          $ctx.client.id,
          clientDetails
        );
        updatedClient.contact_info = JSON.parse(updatedClient.contact_info);

        $ctx.client = updatedClient;
        return true;
      };

      this.showModal(
        $modal,
        function () {
          $modal.find("[data-client-name]").focus();
        },
        "Salvar",
        action,
        "Perfil atualizado.",
        "Ocorreu um erro editar o perfil do cliente.\nPor favor, tente novamente."
      );
    },

    approveClient() {
      let $ctx = this;

      let action = async function () {
        let clientDetails = {
          status: 2,
        };

        let updatedClient = await AdminManager.updateClient(
          $ctx.client.id,
          clientDetails
        );
        updatedClient.contact_info = JSON.parse(updatedClient.contact_info);
        $ctx.client = updatedClient;
        return true;
      };

      this.showConfirmation(
        "Aprovar perfil",
        "Tem certeza que deseja aprovar o perfil deste cliente?",
        "Aprovar",
        action,
        "O perfil do cliente foi aprovado.",
        "Ocorreu um erro ao aprovar o perfil do cliente.\nPor favor, tente novamente."
      );
    },

    activateClient() {
      let $ctx = this;

      let action = async function () {
        let clientDetails = {
          status: 2,
        };

        let updatedClient = await AdminManager.updateClient(
          $ctx.client.id,
          clientDetails
        );
        updatedClient.contact_info = JSON.parse(updatedClient.contact_info);
        $ctx.client = updatedClient;
        return true;
      };

      this.showConfirmation(
        "Ativar perfil",
        "Tem certeza que deseja ativar o perfil deste cliente?",
        "Ativar",
        action,
        "O perfil do cliente foi ativado.",
        "Ocorreu um erro ao ativar o perfil do cliente.\nPor favor, tente novamente."
      );
    },

    rejectClient() {
      let $ctx = this;

      let action = async function () {
        let clientDetails = {
          status: 3,
        };

        let updatedClient = await AdminManager.updateClient(
          $ctx.client.id,
          clientDetails
        );
        updatedClient.contact_info = JSON.parse(updatedClient.contact_info);
        $ctx.client = updatedClient;
        return true;
      };

      this.showConfirmation(
        "Reprovar perfil",
        "Tem certeza que deseja reprovar o perfil deste cliente?",
        "Reprovar",
        action,
        "O perfil do cliente foi reprovado.",
        "Ocorreu um erro ao reprovar o perfil do cliente.\nPor favor, tente novamente."
      );
    },

    inactivateClient() {
      let $ctx = this;

      let action = async function () {
        let clientDetails = {
          status: 3,
        };

        let updatedClient = await AdminManager.updateClient(
          $ctx.client.id,
          clientDetails
        );
        updatedClient.contact_info = JSON.parse(updatedClient.contact_info);
        $ctx.client = updatedClient;
        return true;
      };

      this.showConfirmation(
        "Desativar perfil",
        "Tem certeza que deseja desativar o perfil deste cliente?",
        "Desativar",
        action,
        "O perfil do cliente foi desativado.",
        "Ocorreu um erro ao desativar o perfil do cliente.\nPor favor, tente novamente."
      );
    }
  },
};
</script>
